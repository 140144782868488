import React, { useContext } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from "@mui/material";

import UserContext from "../../UserContext";
import { mainNavbarItems, getMainNavbarItems } from "./consts/navbarItems";
import { useNavigate } from "react-router-dom";

const NavbarMobile = ({ isMenuOpen, handleHamburger }) => {
  const { user } = useContext(UserContext);

  const navbarItems = getMainNavbarItems(user);

  const navigate = useNavigate();

  const handleNavigate = (item) => {
    handleHamburger();
    item.route && navigate(item.route);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  return (
    <Drawer anchor="left" open={isMenuOpen} onClose={handleHamburger}>
      <List sx={{ width: "100%" }} onClick={handleHamburger}>
        {navbarItems.map((item) => (
          <ListItem
            button
            key={item.id}
            onClick={
              item.id == 5 ? () => handleLogout() : () => handleNavigate(item)
            }
          >
            <ListItemAvatar>
              <Avatar>{item.icon}</Avatar>
            </ListItemAvatar>
            <ListItemText primary={item.label} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default NavbarMobile;
