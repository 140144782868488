import { useState } from "react";
import React from "react";
import {
  IconButton,
  InputAdornment,
  TextField,
  Button,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import NameUpdate from "./nameUpdate";
import ContactUpdate from "./contactUpdate";
import { settingStyles, toastOptions } from "./styles";

import { Formik } from "formik";
import axios from "axios";
import { toast } from "react-toastify";
import * as Yup from "yup";

const bcrypt = require("bcryptjs");

// Creating schema
const schema = Yup.object().shape({
  oldPassword: Yup.string().required("Please enter your old password"),
  password: Yup.string().min(6).required("Please enter your new password"),
  confirmPassword: Yup.string()
    .required("Re-enter password is required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

const Settings = () => {
  const logedInUser = JSON.parse(localStorage.getItem("logedInUser"));
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowOldPassword = () => {
    setShowOldPassword((prevShowOldPassword) => !prevShowOldPassword);
  };

  const handleClickShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(
      (prevShowConfirmPassword) => !prevShowConfirmPassword
    );
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCancelClick = (resetForm) => {
    resetForm();
  };

  return (
    <>
      <Grid sx={settingStyles.outerContainer}>
        <Box sx={settingStyles.outerBox}>
          <Typography variant="h5" sx={settingStyles.pageTitle}>
            Settings
          </Typography>
          <NameUpdate />
          <ContactUpdate />
          <Formik
            validationSchema={schema}
            initialValues={{
              oldPassword: "",
              password: "",
              confirmPassword: "",
            }}
            onSubmit={async (values, { resetForm }) => {
              try {
                const { oldPassword, password } = values;
                await schema.validate(values, { abortEarly: false });
                // Compare the input old password with the stored hashed password

                const logedInUser = await JSON.parse(
                  localStorage.getItem("logedInUser")
                );
                const storedPassword = logedInUser.user.password;
                const token = logedInUser.token;
                const isMatch = await bcrypt.compare(
                  oldPassword,
                  storedPassword
                );

                if (!isMatch) {
                  toast.error(
                    "Old password is incorrect",
                    settingStyles.toastOptions
                  );
                }

                var data = JSON.stringify({
                  password: password,
                });

                var config = {
                  method: "post",
                  url: `${process.env.REACT_APP_BACKEND_URL}/auth/reset-password-profile`,
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                  data: data,
                };

                axios(config)
                  .then(function (response) {
                    toast.success(
                      "Successfully reset password.",
                      settingStyles.toastOptions
                    );
                    // Update and clear the form fields on successful submission
                    values.oldPassword = "";
                    values.password = "";
                    values.confirmPassword = "";
                  })
                  .catch(function (err) {
                    toast.error(
                      err?.response?.data?.message
                        ? err.response.data.message
                        : "Something went wrong",
                      settingStyles.toastOptions
                    );
                  });
              } catch (error) {
                console.error(error);
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              resetForm,
            }) => (
              <div>
                <Box>
                  <form noValidate onSubmit={handleSubmit}>
                    <Typography variant="body1" sx={settingStyles.formLabels}>
                      Email
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      id="email"
                      defaultValue={logedInUser.user.email}
                      name="email"
                      sx={settingStyles.customTextField9px}
                    />
                    <Typography variant="body1" sx={settingStyles.formLabels}>
                      Enter Old Password
                    </Typography>
                    <TextField
                      fullWidth
                      name="oldPassword"
                      type={showOldPassword ? "text" : "password"}
                      id="oldPassword"
                      autoComplete="off"
                      value={values.oldPassword}
                      onChange={handleChange}
                      error={touched.oldPassword && Boolean(errors.oldPassword)}
                      helperText={touched.oldPassword && errors.oldPassword}
                      onBlur={handleBlur}
                      sx={settingStyles.customTextField9px}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowOldPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showOldPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Typography variant="body1" sx={settingStyles.formLabels}>
                      Enter New Password
                    </Typography>
                    <TextField
                      fullWidth
                      name="password"
                      type={showPassword ? "text" : "password"}
                      id="password"
                      autoComplete="off"
                      value={values.password}
                      onChange={handleChange}
                      error={touched.password && Boolean(errors.password)}
                      helperText={touched.password && errors.password}
                      onBlur={handleBlur}
                      sx={settingStyles.customTextField9px}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Typography variant="body1" sx={settingStyles.formLabels}>
                      Re-enter Password
                    </Typography>
                    <TextField
                      fullWidth
                      required
                      name="confirmPassword"
                      type={showConfirmPassword ? "text" : "password"}
                      id="confirmPassword"
                      autoComplete="off"
                      value={values.confirmPassword}
                      onChange={handleChange}
                      error={
                        touched.confirmPassword &&
                        Boolean(errors.confirmPassword)
                      }
                      helperText={
                        touched.confirmPassword && errors.confirmPassword
                      }
                      onBlur={handleBlur}
                      sx={settingStyles.customTextField9px}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle confirm password visibility"
                              onClick={handleClickShowConfirmPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showConfirmPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Box sx={settingStyles.buttonsBox}>
                      <Button
                        type="submit"
                        variant="contained"
                        sx={settingStyles.otherButtons}
                      >
                        Reset Password
                      </Button>
                      <Button
                        type="reset"
                        onClick={() => handleCancelClick(resetForm)}
                        variant="contained"
                        sx={{ ...settingStyles.otherButtons, marginLeft: "3%" }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </form>
                </Box>
              </div>
            )}
          </Formik>
        </Box>
      </Grid>
    </>
  );
};

export default Settings;
