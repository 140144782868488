import { useContext } from "react";
import UserContext from "../../../UserContext";

import Users from "../../icons/Users";
import Dashboard from "../../icons/Dashboard";
import Load from "../../icons/Load";
import Equipment from "../../icons/Equipment";
import Settings from "../../icons/Settings";
import Logout from "../../icons/Logout";
import "../Navbar.css";

export const UsersIcon = (props) => {
  const { user } = useContext(UserContext);
  if (user?.isAdmin) {
    return (
      <div
        style={
          props.currentActive === "Users"
            ? props.styles?.activeIcon
            : props.styles?.inActiveIcon
        }
      >
        {" "}
        <Users />
      </div>
    );
  } else {
    return null;
  }
};

export const DashboardIcon = (props) => {
  return (
    <div
      style={
        props.currentActive === "Dashboard"
          ? props.styles?.activeIcon
          : props.styles?.inActiveIcon
      }
    >
      {" "}
      <Dashboard />
    </div>
  );
};

export const LoadIcon = (props) => {
  return (
    <div
      style={
        props.currentActive === "Load"
          ? props.styles?.activeIcon
          : props.styles?.inActiveIcon
      }
    >
      {" "}
      <Load />
    </div>
  );
};

export const EquipmentIcon = (props) => {
  return (
    <div
      style={
        props.currentActive === "Equipment"
          ? props.styles?.activeIcon
          : props.styles?.inActiveIcon
      }
    >
      {" "}
      <Equipment />
    </div>
  );
};

export const SettingsIcon = (props) => {
  return (
    <div
      style={
        props.currentActive === "Settings"
          ? props.styles?.activeIcon
          : props.styles?.inActiveIcon
      }
    >
      {" "}
      <Settings />
    </div>
  );
};

export const LogoutIcon = () => {
  return <Logout />;
};

export const getMainNavbarItems = (user) => {
  if (user?.isAdmin) {
    // If the user is an admin, return items with ids 0, 4, and 5
    return mainNavbarItems.filter(
      (item) => item.id === 0 || item.id === 4 || item.id === 5
    );
  } else {
    // If the user is not an admin, return items with ids 1 to 5
    return mainNavbarItems.filter((item) => item.id >= 1 && item.id <= 5);
  }
};

export const mainNavbarItems = [
  {
    id: 0,
    icon: <UsersIcon />,
    label: "Users",
    route: "/users",
    iconKey: "stroke",
  },
  {
    id: 1,
    icon: <DashboardIcon />,
    label: "Dashboard",
    route: "/dashboard",
    iconKey: "stroke",
  },
  {
    id: 2,
    icon: <LoadIcon />,
    label: "Loads",
    route: "load",
    iconKey: "stroke",
  },
  {
    id: 3,
    icon: <EquipmentIcon />,
    label: "Equipment",
    route: "equipment",
    iconKey: "stroke",
  },
  {
    id: 4,
    icon: <SettingsIcon />,
    label: "Settings",
    route: "settings",
    iconKey: "stroke",
  },
  {
    id: 5,
    icon: <LogoutIcon />,
    label: "Logout",
    // route: "logout",
  },
];
