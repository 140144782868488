import { Box, Grid, useMediaQuery } from "@mui/material";
import OwnLoads from "../../components/Posting/OwnLoads";
import OwnEquipments from "../../components/Posting/OwnEquipments";

export default function Dashboard() {
  const isSmallScreen = useMediaQuery("(max-width:500px)");
  return (
    <>
      <Grid item xs>
        <Box
          sx={{
            my: { xs: 2.5, sm: 2.5, md: 8 },
            mx: 4,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <OwnLoads />
          {isSmallScreen && (
            <Grid item xs={12}>
              <Box mt={2} />
            </Grid>
          )}
          <OwnEquipments />
        </Box>
      </Grid>
    </>
  );
}
