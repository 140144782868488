import { useRef, useState, useEffect } from "react";
import axios from "axios";
import ReactMapGL, { Marker, FullscreenControl } from "react-map-gl";
import { lineString } from "@turf/helpers";
import { CircularProgress } from "@mui/material";
import Durations from "./Durations";
import { responsiveStyles } from "./styles";
import mapMarkerIcon from "../../assets/icons/mapMarkerIcon.svg";

export default function MapCan({ key, originName, destinationName }) {
  const mapRef = useRef(null);
  const isCanceled = useRef(false);
  const [viewport, setViewport] = useState({
    latitude: 45.4215,
    longitude: -75.6972,
    zoom: 5,
  });
  const [originMarkerPosition, setOriginMarkerPosition] = useState({});
  const [destinationMarkerPosition, setDestinationMarkerPosition] = useState(
    {}
  );
  const [route, setRoute] = useState(null);
  const [durations, setDurations] = useState({
    driving: null,
    cycling: null,
    walking: null,
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleViewportChange = (newViewport) => {
    setViewport(newViewport);
  };

  useEffect(() => {
    if (originName) {
      updateCoordsByName(originName, "origin");
    }
    if (destinationName) {
      updateCoordsByName(destinationName, "destination");
    }

    // Clear the previous states
    setDurations({
      driving: null,
      cycling: null,
      walking: null,
    });
    setRoute(null);

    return () => {
      isCanceled.current = true;
    };
  }, [originName, destinationName]);

  useEffect(() => {
    if (originMarkerPosition && destinationMarkerPosition) {
      updateRouteAndDurations(originMarkerPosition, destinationMarkerPosition);
    }
  }, [originMarkerPosition, destinationMarkerPosition]);

  useEffect(() => {
    if (originMarkerPosition.latitude && originMarkerPosition.longitude) {
      setViewport((prevViewport) => ({
        ...prevViewport,
        latitude: originMarkerPosition.latitude,
        longitude: originMarkerPosition.longitude,
      }));
    }
  }, [originMarkerPosition]);

  const updateCoordsByName = async (name, type) => {
    try {
      const response = await axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
          name
        )}.json?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}&limit=1`
      );

      if (
        response.data.features &&
        response.data.features.length > 0 &&
        response.data.features[0].center
      ) {
        const [lng, lat] = response.data.features[0].center;
        if (type === "origin") {
          setOriginMarkerPosition({ latitude: lat, longitude: lng });
        } else {
          setDestinationMarkerPosition({ latitude: lat, longitude: lng });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateRouteAndDurations = async (newOrigin, newDestination) => {
    setIsLoading(true);
    isCanceled.current = false;
    if (
      newOrigin &&
      newDestination &&
      newOrigin.latitude &&
      newOrigin.longitude &&
      newDestination.latitude &&
      newDestination.longitude
    ) {
      const drivingData = await fetchRoute(
        newOrigin,
        newDestination,
        "driving"
      );
      const cyclingData = await fetchRoute(
        newOrigin,
        newDestination,
        "cycling"
      );
      const walkingData = await fetchRoute(
        newOrigin,
        newDestination,
        "walking"
      );
      if (!isCanceled.current) {
        setDurations({
          driving: drivingData.duration,
          cycling: cyclingData.duration,
          walking: walkingData.duration,
        });
        setRoute(drivingData.route);
        setIsLoading(false);
      }
    }
  };

  const fetchRoute = async (origin, destination, profile) => {
    const url = `https://api.mapbox.com/directions/v5/mapbox/${profile}/${origin.longitude},${origin.latitude};${destination.longitude},${destination.latitude}?steps=true&geometries=geojson&access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`;

    try {
      const response = await axios.get(url);

      if (response.data.routes && response.data.routes.length > 0) {
        const routeData = lineString(
          response.data.routes[0].geometry.coordinates
        );
        return { duration: response.data.routes[0].duration, route: routeData };
      } else {
        return { duration: "N/A", route: null };
      }
    } catch (error) {
      console.error(error);
      return { duration: "N/A", route: null };
    }
  };

  return (
    <>
      <ReactMapGL
        ref={mapRef}
        {...viewport}
        style={responsiveStyles.mapContainer}
        mapStyle={responsiveStyles.mapStyle}
        attributionControl={false}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        onMove={(evt) => setViewport(evt.viewState)}
      >
        {originMarkerPosition?.latitude && originMarkerPosition?.longitude && (
          <Marker
            latitude={originMarkerPosition?.latitude}
            longitude={originMarkerPosition?.longitude}
            draggable={false}
          >
            <img
              src={mapMarkerIcon}
              alt="Map Marker"
              width="24px"
              height="24px"
            />
          </Marker>
        )}
        {destinationMarkerPosition.latitude &&
          destinationMarkerPosition.longitude && (
            <Marker
              latitude={destinationMarkerPosition.latitude}
              longitude={destinationMarkerPosition.longitude}
              draggable={false}
            >
              <img
                src={mapMarkerIcon}
                alt="Map Marker"
                width="24px"
                height="24px"
              />
            </Marker>
          )}
        <Durations
          updateRouteAndDurations={updateRouteAndDurations}
          route={route}
          durations={durations}
        />
        <FullscreenControl position="bottom-right" />
        {isLoading && <CircularProgress size={20} sx={{ margin: "1%" }} />}
      </ReactMapGL>
    </>
  );
}
