import React from "react";

const Motorcycle = ({ color }) => {
  return (
    <svg
      width="16"
      height="11"
      viewBox="0 0 16 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 3.7041H2.5M2.5 3.7041L3.5 4.7041M2.5 3.7041L5 4.2041H8L10.25 2.7041M3.5 4.7041C3.5 4.7041 2.17774 5.15345 1.5 5.7041C-0.607519 7.41644 0.901726 10.3488 3.5 9.7041C3.92377 9.59895 4.19126 9.51284 4.5 9.2041C4.80874 8.89536 5 8.2041 5 8.2041M3.5 4.7041L5 5.7041V7.2041V8.2041M5 8.2041H8C8 8.2041 7.99367 6.00905 9 5.7041C9.37374 5.59085 10 5.7041 10 5.7041M10 5.7041C10 5.7041 9.52586 7.35065 10 8.2041C10.2682 8.68689 10.5466 8.88871 11 9.2041C13.3213 10.8189 16.2183 7.92652 14.5 5.7041C14.0694 5.14713 13.6705 4.91867 13 4.7041C12.2561 4.46606 11 4.7041 11 4.7041M10 5.7041L11 4.7041M11 4.7041V3.2041M11 3.2041L10.25 2.7041M11 3.2041L13 2.7041L12 1.2041L10.25 2.7041M5.5 1.2041L9.5 2.2041L10.25 2.7041"
        stroke={color || "white"}
        stroke-width="0.8"
        // fill={color || "white"}
      />
    </svg>
  );
};

export default Motorcycle;
