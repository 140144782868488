import React, { useState, useEffect, useContext } from "react";
import axios from "axios";

import { toast } from "react-toastify";
import { toastOptionsAlt } from "../componentStyles";

import useWindowDimensions from "../../hooks/useWindowDimensions";
import UserContext from "../../UserContext";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Box } from "@mui/material";
import { mainNavbarItems, getMainNavbarItems } from "./consts/navbarItems";
import { navbarStyles } from "./styles";
import { useNavigate } from "react-router-dom";
import "../../../src/index.css";
import dashboardLogo from "../../assets/images/dashboardLogo.svg";

const Navbar = () => {
  const { height, width } = useWindowDimensions();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const [currentActive, setCurrentActive] = useState(
    user?.isAdmin ? "Users" : "Dashboard"
  );

  // Get the filtered navbarItems based on user's admin status
  const navbarItems = getMainNavbarItems(user);

  const logedInUser = JSON.parse(localStorage.getItem("logedInUser"));
  const token = logedInUser?.token;

  const handleNavigate = (item) => {
    navigate(item.route);
    setCurrentActive(item.label);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  let url = window.location.pathname;

  useEffect(() => {
    // Make the API call to get the user data and check if isDisabled is true or false
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/auth/getUser`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Show toast message if user account is disabled
        if (response?.data?.user?.isDisabled) {
          await toast.error(
            "Your account is disabled. Please contact the administrator.",
            toastOptionsAlt
          );
          handleLogout();
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchUserData();
  }, [url, token, user]);

  return (
    <Box>
      <Drawer
        components={"div"}
        mt={5}
        sx={navbarStyles.drawer}
        variant="permanent"
        anchor="left"
      >
        <Toolbar
          sx={{
            height: "13vh",
            my: { md: "6.5%", lg: "4.5%", xl: "1.5%" },
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img src={dashboardLogo} alt="LC Logo" />
        </Toolbar>
        <List
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          {navbarItems.map((item, index) => (
            <ListItem
              style={item.id == 5 ? navbarStyles.logout : {}}
              sx={{
                mb: 3,
                display: "flex",
                pl: 5,
                flexWrap: "nowrap",
                textAlign: "left",
                ...navbarStyles.listItemContainer,
              }}
              button
              key={item.id}
              className={`${item.iconKey} ${
                item.label == currentActive && "active"
              }`}
              onClick={
                item.id == 5 ? () => handleLogout() : () => handleNavigate(item)
              }
            >
              {item.icon}
              <ListItemText sx={navbarStyles.text} primary={item.label} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Box>
  );
};

export default Navbar;
