export const componentStyles = {
  pageHeading: {
    fontWeight: 700,
    color: "#212121",
    fontFamily: "Helvetica",
    display: "flex",
    justifyContent: "center",
  },

  pageHeadingAlt: {
    fontWeight: 500,
    color: "#142953",
    fontFamily: "Inter",
    display: "flex",
    justifyContent: "flex-start",
  },

  pageSubHeading: {
    fontWeight: 400,
    color: "#545454",
    fontFamily: "Inter",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  // Box for Registration Details Form
  boxDetails: {
    mb: 1,
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
  },

  formLabels: {
    color: "#142953",
    fontWeight: "500",
    fontSize: { sm: "12px", md: "14px", lg: "16px" },
  },

  formFields: {
    "& .MuiOutlinedInput-root": {
      "& > fieldset": {
        borderRadius: "4px",
        border: "1px solid #142953",
      },
      "&.Mui-focused > fieldset": {
        borderColor: "#142953",
      },
    },
  },

  formSelectFields: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "4px",
      border: "1px solid #142953",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#142953",
    },
  },

  customTextField20px: {
    mb: "20px",
    "& .MuiOutlinedInput-root": {
      "& > fieldset": {
        border: "1.01689px solid #142953",
        opacity: "0.5",
      },
    },
  },

  customTextField9px: {
    mb: "9px",
    "& .MuiOutlinedInput-root": {
      "& > fieldset": {
        border: "1.01689px solid #142953",
        opacity: "0.5",
      },
    },
  },

  customTextField8px: {
    mb: "8px",
    "& .MuiOutlinedInput-root": {
      "& > fieldset": {
        border: "1.01689px solid #142953",
        opacity: "0.5",
      },
    },
  },

  font50Px: {
    xs: "30px",
    sm: "50px",
  },

  font24Px: {
    xs: "20px",
    sm: "24px",
  },

  submitButton: {
    fontSize: "20px",
    letterSpacing: "0.2rem",
    fontWeight: "600",
    color: "#FFFFFF",
    background: "#142953",
    border: "1px solid #212121",
    textTransform: "capitalize",
    height: "60px",
    my: { xs: 1, sm: 2, md: 4, lg: 5, xl: 5 },
  },

  submitButtonReg: {
    fontSize: "20px",
    letterSpacing: "0.2rem",
    fontWeight: "600",
    color: "#FFFFFF",
    background: "#142953",
    border: "1px solid #212121",
    textTransform: "capitalize",
    height: "60px",
    my: { xs: 1, sm: 1, md: 2, lg: 3, xl: 3 },
  },

  submitButtonRegDetails: {
    backgroundColor: "#142953",
    color: "#FFFFFF",
    my: { sm: "1.5%", md: "2%" },
  },

  buttonBox: {
    display: "flex",
    justifyContent: "flex-end !important",
  },

  buttonSubText: {
    display: "flex",
    alignItems: "center",
    fontWeight: "400",
    color: "#212121",
    fontSize: { xs: "14px", sm: "18px", md: "24px" },
    fontFamily: "Helvetica",
  },

  formLinks: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
};

export const toastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
  zIndex: 9999,
};

export const toastOptionsAlt = {
  position: "top-center",
  autoClose: 7000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
  zIndex: 9999,
};
