import React from "react";

const Edit = ({ color }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.713 16.0117C13.4824 16.2422 13.1974 16.4183 12.8886 16.5213L9.90539 17.5156C9.69009 17.5874 9.46556 17.6239 9.2384 17.6239C8.67492 17.6239 8.14518 17.4045 7.74679 17.006C7.17815 16.4373 6.98297 15.6102 7.23726 14.8474L8.23167 11.8644C8.33456 11.5554 8.51081 11.2702 8.7412 11.0398L15.1844 4.59668H2.57812C1.15655 4.59668 0 5.75323 0 7.1748V22.1748C0 23.5964 1.15655 24.7529 2.57812 24.7529H17.5781C18.9997 24.7529 20.1562 23.5964 20.1562 22.1748V9.56852L13.713 16.0117Z"
        fill={color || "#142953"}
      />
      <path
        d="M9.7355 12.0341C9.6583 12.1113 9.60017 12.2053 9.56567 12.3089L8.57127 15.292C8.48703 15.5446 8.5528 15.8232 8.74109 16.0115C8.92944 16.1998 9.20797 16.2656 9.46062 16.1813L12.4437 15.187C12.5473 15.1525 12.6414 15.0943 12.7186 15.0171L21.5022 6.23357L18.5191 3.25049L9.7355 12.0341Z"
        fill={color || "#142953"}
      />
      <path
        d="M22.331 1.09616C21.8734 0.63852 21.1314 0.63852 20.6738 1.09616L19.5137 2.25627L22.4968 5.23939L23.6569 4.07928C24.1145 3.62164 24.1145 2.87966 23.6569 2.42202L22.331 1.09616Z"
        fill={color || "#142953"}
      />
    </svg>
  );
};

export default Edit;
