import React from "react";

const Load = () => {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ display: "flex" }}
    >
      <path
        d="M4.7909 15.4067C4.7909 15.749 4.44967 16.0925 4.1073 15.9773H2.62482C1.14236 15.9773 0 14.836 0 13.4665V2.51073C0 1.14125 1.14008 0 2.62482 0H10.6146C11.9841 0 13.2372 1.14125 13.2372 2.51073V6.27678C13.2372 6.61915 12.8971 6.8474 12.5536 6.8474C12.2101 6.8474 11.983 6.50275 11.983 6.27678V2.51073C11.983 1.82713 11.4123 1.25651 10.7264 1.25651H2.73665C2.05306 1.25651 1.48018 1.82713 1.48018 2.51073V13.4677C1.48018 14.1513 2.05077 14.7219 2.73665 14.7219H4.22028C4.44738 14.7208 4.7909 14.9513 4.7909 15.4067Z"
        fill="#142953"
      />
      <path
        d="M8.89929 4.33661C8.89929 4.10836 8.66991 3.87671 8.44281 3.87671H3.87787C3.64734 3.87671 3.42139 4.10608 3.42139 4.33661C3.42139 4.56714 3.64849 4.79198 3.87787 4.79198H8.44281C8.66991 4.68014 8.89929 4.56486 8.89929 4.33661Z"
        fill="#142953"
      />
      <path
        d="M6.61713 6.84743C6.61713 6.62146 6.38775 6.39209 6.16064 6.39209H3.30756C3.07817 6.39209 2.85107 6.62032 2.85107 6.84743C2.85107 7.07681 3.07931 7.30506 3.30756 7.30506H6.16064C6.38775 7.30278 6.61713 7.07567 6.61713 6.84743Z"
        fill="#142953"
      />
      <path
        d="M3.19428 8.90161C2.96375 8.90161 2.73779 9.12988 2.73779 9.35698C2.73779 9.58637 2.96489 9.81688 3.19428 9.81688H5.02026C5.24737 9.81688 5.47675 9.58637 5.47675 9.35698C5.47675 9.12645 5.24622 8.90161 5.02026 8.90161H3.19428Z"
        fill="#142953"
      />
      <path
        d="M14.1502 15.6348C14.0361 15.8631 13.6938 15.9772 13.4667 15.9772H9.12883C8.67233 15.9772 8.21582 15.5218 8.21582 15.0653C8.21582 14.9501 8.21582 14.9501 8.21582 14.8382C8.44521 13.8123 9.12881 13.0111 9.92767 12.671C9.70056 12.3275 9.47118 11.9852 9.47118 11.5309C9.47118 10.618 10.2712 9.81909 11.2972 9.81909C12.2102 9.81909 13.0102 10.618 13.0102 11.5309C13.0102 11.9863 12.896 12.3298 12.5537 12.671C13.4667 13.1287 14.0361 13.9253 14.2655 14.8382C14.3774 15.1783 14.3774 15.4066 14.1502 15.6348Z"
        fill="#142953"
      />
    </svg>
  );
};

export default Load;
