import React from "react";

const Dashboard = () => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ display: "flex" }}
    >
      <path
        d="M13.8783 16H11.0169C10.7891 16 10.5591 15.7711 10.4457 15.541V11.7651C10.4457 11.3038 9.9879 10.9627 9.53007 10.9627H8.04329C7.58546 10.9627 7.24092 11.3038 7.12761 11.7651V15.541C7.12761 15.7688 6.89984 16 6.55532 16H3.69273C2.66376 16 1.86144 15.3098 1.74698 14.2808V9.47592H1.5192C1.17583 9.47592 0.71687 9.35801 0.490245 9.01464C-0.0820421 8.55795 -0.195362 7.6412 0.37578 7.06892L0.490245 6.9556L7.69989 0.429215C8.38778 -0.143072 9.30344 -0.143072 9.98904 0.429215L17.1999 6.9556C17.771 7.41229 17.8854 8.32792 17.3143 8.90021L17.1999 9.01352C16.8565 9.24472 16.5142 9.4748 16.0564 9.4748H15.8286V14.2797C15.7096 15.3121 14.9073 16 13.8783 16ZM8.04213 10.0482H9.64568C10.6747 10.0482 11.477 10.7338 11.5914 11.7674V15.0855H13.8806C14.3384 15.0855 14.6829 14.7421 14.7951 14.2831V9.01694C14.7951 8.78803 15.024 8.56027 15.3674 8.56027H16.053C16.1675 8.56027 16.3964 8.56024 16.5108 8.44235C16.7386 8.21572 16.7386 7.98566 16.5108 7.75675L9.29887 1.23385C9.0711 1.00494 8.72657 1.00494 8.49765 1.23385L1.28685 7.75675C1.17239 7.87235 1.05792 7.98569 1.05792 8.1013C1.05792 8.2169 1.17239 8.32904 1.28685 8.44235C1.40016 8.56024 1.51575 8.56027 1.74352 8.56027H2.54589C2.77709 8.56027 3.00372 8.78688 3.11817 9.01694V14.2831C3.11817 14.7421 3.57598 15.0855 4.03267 15.0855H6.32182V11.7674C6.21079 10.7338 7.01087 9.93375 8.04213 10.0482Z"
        fill="#142953"
      />
    </svg>
  );
};

export default Dashboard;
