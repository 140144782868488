import React from "react";

const Equipment = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ display: "flex" }}
    >
      <path
        d="M13.4857 16H2.51315C1.14172 16 0 14.8594 0 13.488V6.05943C0 5.26057 0.457135 4.4583 1.14171 4.0023L2.62629 3.08684V0.80113C2.62629 0.229701 2.968 0 3.31086 0H12.7943C13.1372 0 13.3657 0.228571 13.3657 0.571429V2.85714L14.8514 3.88456C15.536 4.34399 15.9931 5.14284 15.9931 5.94399V10.288C15.9931 10.6309 15.7646 10.8594 15.4217 10.8594C15.0788 10.8594 14.8514 10.6309 14.8514 10.288V6.85714L9.36572 10.288C8.56458 10.744 7.53714 10.744 6.73828 10.288L1.2537 6.85714V13.488C1.2537 14.1726 1.82399 14.744 2.51085 14.744H13.4834C14.168 14.744 14.7405 14.1726 14.7405 13.488C14.7405 13.1451 14.9691 12.9166 15.312 12.9166C15.6525 12.9166 15.8823 13.1451 15.8823 13.488C15.9989 14.8571 14.8548 16 13.4857 16ZM3.88571 1.2583V6.97259L7.31543 9.14286C7.77257 9.36914 8.22855 9.36914 8.68684 9.14286L12.1166 6.97259V1.2583H3.88571ZM13.3703 4.45599V6.28571L14.6286 5.48458C14.512 5.2583 14.3989 5.14058 14.1714 5.0263L13.3703 4.45599ZM2.62741 4.45599L1.82628 4.91542C1.59885 5.02857 1.48571 5.25941 1.36914 5.4857L2.62741 6.28802V4.45599Z"
        fill="#142953"
      />
      <path
        d="M5.82826 3.31323H8.34368C8.57111 3.31323 8.80082 3.54066 8.80082 3.77152C8.80082 4.00009 8.57339 4.22754 8.34368 4.22754H5.82826C5.59969 4.22754 5.37109 4.00123 5.37109 3.77152C5.37109 3.42866 5.59741 3.31323 5.82826 3.31323Z"
        fill="#142953"
      />
      <path
        d="M5.59759 4.91431H6.96903C7.19874 4.91431 7.42616 5.14061 7.42616 5.37033C7.42616 5.5989 7.1976 5.71317 6.96903 5.71317H5.59759C5.36787 5.71317 5.1416 5.59775 5.1416 5.37033C5.1416 5.1429 5.3713 4.91431 5.59759 4.91431Z"
        fill="#142953"
      />
    </svg>
  );
};

export default Equipment;
