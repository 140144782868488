import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  InputAdornment,
  useMediaQuery,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import { modalStyles, viewPost, toastOptions } from "./styles";
import MapCan from "../MapBox/MapBox";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { styles } from "../MapBox/styles";
import Autocomplete from "./Autocomplete";

const CreatePost = ({ handleClose, open }) => {
  const isSmallScreen = useMediaQuery("(max-width:500px)");
  const [dateValue, setDateValue] = useState(dayjs());
  const [originName, setOriginName] = useState(null);
  const [originCoordinates, setOriginCoordinates] = useState(null);
  const [destinationName, setDestinationName] = useState(null);
  const [destinationCoordinates, setDestinationCoordinates] = useState(null);
  const [formValuesFilled, setFormValuesFilled] = useState(false);
  const [formValues, setFormValues] = useState({
    originRadius: "",
    destinationRadius: "",
    weight: "",
    length: "",
  });

  const logedInUser = JSON.parse(localStorage.getItem("logedInUser"));
  const userCompany = logedInUser.user.companyName;

  const [type, setType] = useState({
    load: false,
    equipment: false,
  });

  const [size, setSize] = useState({
    full: false,
    less: false,
    both: false,
  });

  const [equipmentTypes, setEquipmentTypes] = useState({
    vanDryBox: false,
    container: false,
    curtainSide: false,
    flatBed: false,
    lowBoyRGN: false,
    rollTiteTrailer: false,
    rackAndTarp: false,
    straightTruck: false,
    dumpTrailer: false,
    floats: false,
    doubleDrop: false,
    reefer: false,
    superB: false,
    other: false,
  });

  const [postingAttributes, setPostingAttributes] = useState({
    airRide: false,
    tarps: false,
    heat: false,
    bTrain: false,
    vented: false,
    expedite: false,
    blanketWrap: false,
    triAxle: false,
    inbound: false,
    chains: false,
    team: false,
    hazMat: false,
    tailgate: false,
    insulated: false,
    frozen: false,
  });

  const resetForm = () => {
    setType({
      load: false,
      equipment: false,
    });
    setEquipmentTypes({
      vanDryBox: false,
      container: false,
      curtainSide: false,
      flatBed: false,
      lowBoyRGN: false,
      rollTiteTrailer: false,
      rackAndTarp: false,
      straightTruck: false,
      dumpTrailer: false,
      floats: false,
      doubleDrop: false,
      reefer: false,
      superB: false,
      other: false,
    });
    setPostingAttributes({
      airRide: false,
      tarps: false,
      heat: false,
      bTrain: false,
      vented: false,
      expedite: false,
      blanketWrap: false,
      triAxle: false,
      inbound: false,
      chains: false,
      team: false,
      hazMat: false,
      tailgate: false,
      insulated: false,
      frozen: false,
    });
    setFormValues({
      company: "",
      originRadius: "",
      destinationRadius: "",
      weight: "",
      length: "",
    });
    setOriginName(null);
    setDestinationName(null);
    setDateValue(dayjs());
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const handleSizeChange = (event) => {
    setSize(event.target.value);
  };

  const handleOriginSelected = (suggestion) => {
    setOriginName(suggestion.place_name);
    setOriginCoordinates(suggestion.geometry.coordinates);
  };

  const handleDestinationSelected = (suggestion) => {
    setDestinationName(suggestion.place_name);
    setDestinationCoordinates(suggestion.geometry.coordinates);
  };

  const handleEquipmentTypesChange = (event) => {
    setEquipmentTypes({
      ...equipmentTypes,
      [event.target.name]: event.target.checked,
    });
  };

  const handlePostingAttributesChange = (event) => {
    setPostingAttributes({
      ...postingAttributes,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      // Construct the remaining payload data for the API call
      const createdBy = logedInUser.user.fullName;
      const userId = logedInUser.user.id;
      const contactno = logedInUser.user.contactno;
      const company = logedInUser.user.companyName;
      const email = logedInUser.user.email;

      // Construct the selected equipment types string
      let selectedEquipmentTypes = "";
      for (const [key, value] of Object.entries(equipmentTypes)) {
        if (value) {
          if (selectedEquipmentTypes) {
            selectedEquipmentTypes += ", ";
          }
          selectedEquipmentTypes += key;
        }
      }

      // Construct the selected posting attributes string
      let selectedPostingAttributes = "";
      for (const [key, value] of Object.entries(postingAttributes)) {
        if (value) {
          if (selectedPostingAttributes) {
            selectedPostingAttributes += ", ";
          }
          selectedPostingAttributes += key;
        }
      }

      const dateString = dateValue;
      const loadDate = new Date(dateString);

      // Check if all required form values are not null
      if (
        type !== null &&
        size !== null &&
        Object.values(formValues).every((value) => value !== null) &&
        loadDate !== null &&
        originName !== null &&
        destinationName !== null
      ) {
        setFormValuesFilled(true);
      } else {
        setFormValuesFilled(false);
        toast.error(
          "Please fill the required fields indicated with *",
          styles.toastOptions
        );
        return;
      }

      let url = "";
      if (type === "load") {
        url = `${process.env.REACT_APP_BACKEND_URL}/load/add/${userId}`;
      } else if (type === "equipment") {
        url = `${process.env.REACT_APP_BACKEND_URL}/equipment/add/${userId}`;
      }

      const response = await axios.post(url, {
        createdBy,
        type,
        size,
        ...formValues,
        company,
        loadDate,
        origin: originName,
        originLat: originCoordinates[1],
        originLng: originCoordinates[0],
        destination: destinationName,
        destinationLat: destinationCoordinates[1],
        destinationLng: destinationCoordinates[0],
        equipmentTypes: selectedEquipmentTypes,
        postingAttributes: selectedPostingAttributes,
        comments: document.getElementById("comments").value,
        contactno,
        email,
      });

      if (response && response.data) {
        toast.success("Posted successfully!", styles.toastOptions);
        resetForm();
      } else {
        toast.error("Posting failed!", styles.toastOptions);
      }

      handleClose();
    } catch (error) {
      console.error(error);
      toast.error("Posting failed!", styles.toastOptions);
    }
  };

  useEffect(() => {
    return () => {
      setOriginName(null);
      setDestinationName(null);
    };
  }, []);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={modalStyles.modalOuter}
      >
        <Box
          sx={isSmallScreen ? modalStyles.smallStyleBox : modalStyles.boxOuter}
        >
          <Box sx={modalStyles.boxTitle}>
            <Typography variant="h5" component="div" sx={modalStyles.textTitle}>
              New Post
            </Typography>
            <IconButton onClick={handleClose} sx={{ color: "#fff" }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Grid container>
            <Grid item={true} xs={12} sm={12} md={6}>
              <Box sx={{ padding: { sm: "1%", md: "3%" } }}>
                <FormControl
                  component="fieldset"
                  required
                  sx={{
                    width: "70%",
                    display: "flex",
                    justifyContent: {
                      xs: "center",
                      sm: "center",
                      md: "flex-start",
                    },
                  }}
                >
                  <FormLabel htmlFor="type" sx={modalStyles.modalFormLabels}>
                    Type
                  </FormLabel>
                  <RadioGroup
                    name="type"
                    id="type"
                    onChange={handleTypeChange}
                    sx={{
                      "& .MuiSvgIcon-root": { color: "#142953" },
                      display: "inline-flex",
                      width: "fit-content",
                      flexDirection: "row",
                      mb: 1,
                    }}
                  >
                    <FormControlLabel
                      value="load"
                      control={<Radio />}
                      label="Load"
                    />
                    <FormControlLabel
                      value="equipment"
                      control={<Radio />}
                      label="Equipment"
                    />
                  </RadioGroup>
                </FormControl>
                <FormControl
                  component="fieldset"
                  required
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: {
                      xs: "center",
                      sm: "center",
                      md: "flex-start",
                    },
                  }}
                >
                  <FormLabel htmlFor="size" sx={modalStyles.modalFormLabels}>
                    Size
                  </FormLabel>
                  <RadioGroup
                    name="size"
                    id="size"
                    onChange={handleSizeChange}
                    sx={{
                      "& .MuiSvgIcon-root": { color: "#142953" },
                      display: "inline-flex",
                      width: "fit-content",
                      flexDirection: { xs: "column", sm: "column", md: "row" },
                      mb: 1,
                    }}
                  >
                    <FormControlLabel
                      value="full"
                      control={<Radio />}
                      label="Full Truckload"
                    />
                    <FormControlLabel
                      value="less"
                      control={<Radio />}
                      label="Less Than Truckload"
                    />
                    <FormControlLabel
                      value="both"
                      control={<Radio />}
                      label="Both"
                    />
                  </RadioGroup>
                </FormControl>
                {/* Middle section */}
                <FormControl
                  component="fieldset"
                  required
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: {
                      xs: "center",
                      sm: "center",
                      md: "flex-start",
                    },
                    flexDirection: "row",
                  }}
                >
                  <form onSubmit={handleSubmit}>
                    <Box
                      sx={{
                        mb: 1,
                        width: "100%",
                        display: "flex",
                        justifyContent: {
                          xs: "center",
                          sm: "center",
                          md: "flex-start",
                        },
                        flexWrap: {
                          xs: "wrap",
                          sm: "wrap",
                          md: "nowrap",
                        },
                      }}
                    >
                      <FormControl
                        sx={{
                          width: { xs: "100%", sm: "100%", md: "40%" },
                        }}
                        required
                      >
                        <FormLabel
                          htmlFor="company"
                          sx={modalStyles.modalFormLabels}
                        >
                          Company
                        </FormLabel>
                        <TextField
                          id="company"
                          name="company"
                          value={userCompany}
                          sx={modalStyles.modalFormFields}
                          inputProps={{
                            style: {
                              height: "7px",
                            },
                            readOnly: true,
                          }}
                        />
                      </FormControl>
                      <FormControl
                        sx={{
                          width: {
                            xs: "100%",
                            sm: "100%",
                            md: "40%",
                            xl: "40.25%",
                          },
                          mt: { xs: 2, sm: 2, md: 0 },
                          ml: { xs: "0%", sm: "0%", md: 4 },
                        }}
                        required
                      >
                        <FormLabel
                          htmlFor="date"
                          sx={modalStyles.modalFormLabels}
                          inputProps={{
                            style: {
                              height: "17px",
                            },
                          }}
                        >
                          Load Date
                        </FormLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Box
                            sx={{
                              "& .MuiInputBase-root": {
                                height: "40px",
                              },
                            }}
                          >
                            <DatePicker
                              id="date"
                              value={dateValue}
                              onChange={(newValue) => setDateValue(newValue)}
                              sx={modalStyles.modalFormFieldsDate}
                            />
                          </Box>
                        </LocalizationProvider>
                      </FormControl>
                    </Box>
                    <FormControl
                      sx={{
                        mb: 1,
                        width: { xs: "49.5%", sm: "49.5%", md: "40%" },
                      }}
                      required
                    >
                      <FormLabel
                        htmlFor="originName"
                        sx={modalStyles.modalFormLabels}
                      >
                        Origin
                      </FormLabel>
                      <Autocomplete
                        id="originName"
                        value={originName}
                        onPlaceSelected={handleOriginSelected}
                        countryCode="us,ca"
                        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                      />
                    </FormControl>
                    <FormControl
                      required
                      sx={{
                        display: "inline-flex",
                        ml: { xs: "1%", sm: "1%", md: 4 },
                        width: { xs: "49.5%", sm: "49.5%", md: "40%" },
                        "& .MuiOutlinedInput-root": {
                          "& > fieldset": {
                            borderRadius: "4px",
                            border: "1px solid #142953",
                            opacity: "0.8",
                          },
                        },
                      }}
                    >
                      <FormLabel
                        htmlFor="originRadius"
                        sx={modalStyles.modalFormLabels}
                      >
                        Radius
                      </FormLabel>
                      <Box
                        sx={{
                          "& .MuiInputBase-root": {
                            height: "40px",
                            width: "100%",
                          },
                        }}
                      >
                        <Select
                          id="originRadius"
                          name="originRadius"
                          value={formValues.originRadius}
                          onChange={handleChange}
                        >
                          <MenuItem value={5}>5 miles</MenuItem>
                          <MenuItem value={25}>25 miles</MenuItem>
                          <MenuItem value={50}>50 miles</MenuItem>
                          <MenuItem value={100}>100+ miles</MenuItem>
                        </Select>
                      </Box>
                    </FormControl>
                    <FormControl
                      sx={{
                        mb: 1,
                        width: { xs: "49.5%", sm: "49.5%", md: "40%" },
                      }}
                      required
                    >
                      <FormLabel
                        htmlFor="destinationName"
                        sx={modalStyles.modalFormLabels}
                      >
                        Destination
                      </FormLabel>
                      <Autocomplete
                        id="destinationName"
                        value={destinationName}
                        onPlaceSelected={handleDestinationSelected}
                        countryCode="us,ca"
                        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                      />
                    </FormControl>
                    <FormControl
                      required
                      sx={{
                        display: "inline-flex",
                        ml: { xs: "1%", sm: "1%", md: 4 },
                        width: { xs: "49.5%", sm: "49.5%", md: "40%" },
                        "& .MuiOutlinedInput-root": {
                          "& > fieldset": {
                            borderRadius: "4px",
                            border: "1px solid #142953",
                            opacity: "0.8",
                          },
                        },
                      }}
                    >
                      <FormLabel
                        htmlFor="destinationRadius"
                        sx={modalStyles.modalFormLabels}
                      >
                        Radius
                      </FormLabel>
                      <Box
                        sx={{
                          "& .MuiInputBase-root": {
                            height: "40px",
                            width: "100%",
                          },
                        }}
                      >
                        <Select
                          id="destinationRadius"
                          name="destinationRadius"
                          value={formValues.destinationRadius}
                          onChange={handleChange}
                        >
                          <MenuItem value={5}>5 miles</MenuItem>
                          <MenuItem value={25}>25 miles</MenuItem>
                          <MenuItem value={50}>50 miles</MenuItem>
                          <MenuItem value={100}>100+ miles</MenuItem>
                        </Select>
                      </Box>
                    </FormControl>
                    <FormControl
                      required
                      sx={{
                        display: "inline-flex",
                        width: { xs: "49.5%", sm: "49.5%", md: "40%" },
                        mb: 1,
                      }}
                    >
                      <FormLabel
                        htmlFor="weight"
                        sx={modalStyles.modalFormLabels}
                      >
                        Weight
                      </FormLabel>
                      <TextField
                        id="weight"
                        name="weight"
                        required
                        value={formValues.weight}
                        onChange={handleChange}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& > fieldset": {
                              borderRadius: "4px",
                              border: "1px solid #142953",
                              opacity: "0.8",
                            },
                          },
                        }}
                        inputProps={{
                          style: {
                            height: "7px",
                          },
                          endAdornment: (
                            <InputAdornment position="end">lbs</InputAdornment>
                          ),
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">lbs</InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                    <FormControl
                      required
                      sx={{
                        display: "inline-flex",
                        ml: { xs: "1%", sm: "1%", md: 4 },
                        width: { xs: "49.5%", sm: "49.5%", md: "40%" },
                      }}
                    >
                      <FormLabel
                        htmlFor="length"
                        sx={modalStyles.modalFormLabels}
                      >
                        Length
                      </FormLabel>
                      <TextField
                        id="length"
                        name="length"
                        required
                        value={formValues.length}
                        onChange={handleChange}
                        sx={{
                          fontWeight: "500",
                          "& .MuiOutlinedInput-root": {
                            "& > fieldset": {
                              borderRadius: "4px",
                              border: "1px solid #142953",
                              opacity: "0.8",
                            },
                          },
                        }}
                        inputProps={{
                          style: {
                            height: "7px",
                          },
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">ft</InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  </form>
                </FormControl>
                {/* Checkboxes */}
                <FormControl
                  component="fieldset"
                  id="equipment"
                  sx={modalStyles.checkboxesFormControl}
                >
                  <FormLabel
                    htmlFor="equipment"
                    sx={modalStyles.modalFormLabels}
                  >
                    Equipment Types
                  </FormLabel>
                  <Box sx={modalStyles.checkboxesBox}>
                    <Grid container md={12}>
                      <Grid item={true} xs={6} sm={6} md={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={equipmentTypes.vanDryBox}
                              onChange={handleEquipmentTypesChange}
                              name="vanDryBox"
                              sx={modalStyles.svgIcon}
                            />
                          }
                          label={
                            <Typography
                              variant="body1"
                              sx={modalStyles.checkboxesLabel}
                            >
                              Van/Dry Box
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item={true} xs={6} sm={6} md={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={equipmentTypes.container}
                              onChange={handleEquipmentTypesChange}
                              name="container"
                              sx={modalStyles.svgIcon}
                            />
                          }
                          label={
                            <Typography
                              variant="body1"
                              sx={modalStyles.checkboxesLabel}
                            >
                              Container
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item={true} xs={6} sm={6} md={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={equipmentTypes.curtainSide}
                              onChange={handleEquipmentTypesChange}
                              name="curtainSide"
                              sx={modalStyles.svgIcon}
                            />
                          }
                          label={
                            <Typography
                              variant="body1"
                              sx={modalStyles.checkboxesLabel}
                            >
                              Curtain Side
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item={true} xs={6} sm={6} md={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={equipmentTypes.flatBed}
                              onChange={handleEquipmentTypesChange}
                              name="flatBed"
                              sx={modalStyles.svgIcon}
                            />
                          }
                          label={
                            <Typography
                              variant="body1"
                              sx={modalStyles.checkboxesLabel}
                            >
                              Flat Bed
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item={true} xs={6} sm={6} md={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={equipmentTypes.lowBoyRGN}
                              onChange={handleEquipmentTypesChange}
                              name="lowBoyRGN"
                              sx={modalStyles.svgIcon}
                            />
                          }
                          label={
                            <Typography
                              variant="body1"
                              sx={modalStyles.checkboxesLabel}
                            >
                              LowBoy/RGN
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item={true} xs={6} sm={6} md={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={equipmentTypes.rollTiteTrailer}
                              onChange={handleEquipmentTypesChange}
                              name="rollTiteTrailer"
                              sx={modalStyles.svgIcon}
                            />
                          }
                          label={
                            <Typography
                              variant="body1"
                              sx={modalStyles.checkboxesLabel}
                            >
                              Roll Tite Trailer
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item={true} xs={6} sm={6} md={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={equipmentTypes.rackAndTarp}
                              onChange={handleEquipmentTypesChange}
                              name="rackAndTarp"
                              sx={modalStyles.svgIcon}
                            />
                          }
                          label={
                            <Typography
                              variant="body1"
                              sx={modalStyles.checkboxesLabel}
                            >
                              Rack and Tarp
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item={true} xs={6} sm={6} md={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={equipmentTypes.straightTruck}
                              onChange={handleEquipmentTypesChange}
                              name="straightTruck"
                              sx={modalStyles.svgIcon}
                            />
                          }
                          label={
                            <Typography
                              variant="body1"
                              sx={modalStyles.checkboxesLabel}
                            >
                              Straight Truck
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item={true} xs={6} sm={6} md={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={equipmentTypes.dumpTrailer}
                              onChange={handleEquipmentTypesChange}
                              name="dumpTrailer"
                              sx={modalStyles.svgIcon}
                            />
                          }
                          label={
                            <Typography
                              variant="body1"
                              sx={modalStyles.checkboxesLabel}
                            >
                              Dump Trailer
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item={true} xs={6} sm={6} md={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={equipmentTypes.floats}
                              onChange={handleEquipmentTypesChange}
                              name="floats"
                              sx={modalStyles.svgIcon}
                            />
                          }
                          label={
                            <Typography
                              variant="body1"
                              sx={modalStyles.checkboxesLabel}
                            >
                              Floats
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item={true} xs={6} sm={6} md={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={equipmentTypes.doubleDrop}
                              onChange={handleEquipmentTypesChange}
                              name="doubleDrop"
                              sx={modalStyles.svgIcon}
                            />
                          }
                          label={
                            <Typography
                              variant="body1"
                              sx={modalStyles.checkboxesLabel}
                            >
                              Double Drop
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item={true} xs={6} sm={6} md={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={equipmentTypes.reefer}
                              onChange={handleEquipmentTypesChange}
                              name="reefer"
                              sx={modalStyles.svgIcon}
                            />
                          }
                          label={
                            <Typography
                              variant="body1"
                              sx={modalStyles.checkboxesLabel}
                            >
                              Reefer
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item={true} xs={6} sm={6} md={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={equipmentTypes.superB}
                              onChange={handleEquipmentTypesChange}
                              name="superB"
                              sx={modalStyles.svgIcon}
                            />
                          }
                          label={
                            <Typography
                              variant="body1"
                              sx={modalStyles.checkboxesLabel}
                            >
                              Super B
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item={true} xs={6} sm={6} md={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={equipmentTypes.stepdeck}
                              onChange={handleEquipmentTypesChange}
                              name="stepDeck"
                              sx={modalStyles.svgIcon}
                            />
                          }
                          label={
                            <Typography
                              variant="body1"
                              sx={modalStyles.checkboxesLabel}
                            >
                              Step Deck
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item={true} xs={6} sm={6} md={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={equipmentTypes.powerOnly}
                              onChange={handleEquipmentTypesChange}
                              name="powerOnly"
                              sx={modalStyles.svgIcon}
                            />
                          }
                          label={
                            <Typography
                              variant="body1"
                              sx={modalStyles.checkboxesLabel}
                            >
                              Power Only
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item={true} xs={6} sm={6} md={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={equipmentTypes.other}
                              onChange={handleEquipmentTypesChange}
                              name="other"
                              sx={modalStyles.svgIcon}
                            />
                          }
                          label={
                            <Typography
                              variant="body1"
                              sx={modalStyles.checkboxesLabel}
                            >
                              Other
                            </Typography>
                          }
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </FormControl>
                <FormControl
                  component="fieldset"
                  id="postingAttributes"
                  sx={modalStyles.checkboxesFormControl}
                >
                  <FormLabel
                    htmlFor="postingAttributes"
                    sx={modalStyles.modalFormLabels}
                  >
                    Posting Attributes
                  </FormLabel>
                  <Box sx={modalStyles.checkboxesBox}>
                    <Grid container md={12}>
                      <Grid item={true} xs={6} sm={6} md={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={postingAttributes.airRide}
                              onChange={handlePostingAttributesChange}
                              name="airRide"
                              sx={modalStyles.svgIcon}
                            />
                          }
                          label={
                            <Typography
                              variant="body1"
                              sx={modalStyles.checkboxesLabel}
                            >
                              Air Ride
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item={true} xs={6} sm={6} md={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={postingAttributes.tarps}
                              onChange={handlePostingAttributesChange}
                              name="tarps"
                              sx={modalStyles.svgIcon}
                            />
                          }
                          label={
                            <Typography
                              variant="body1"
                              sx={modalStyles.checkboxesLabel}
                            >
                              Tarps
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item={true} xs={6} sm={6} md={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={postingAttributes.heat}
                              onChange={handlePostingAttributesChange}
                              name="heat"
                              sx={modalStyles.svgIcon}
                            />
                          }
                          label={
                            <Typography
                              variant="body1"
                              sx={modalStyles.checkboxesLabel}
                            >
                              Heat
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item={true} xs={6} sm={6} md={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={postingAttributes.bTrain}
                              onChange={handlePostingAttributesChange}
                              name="bTrain"
                              sx={modalStyles.svgIcon}
                            />
                          }
                          label={
                            <Typography
                              variant="body1"
                              sx={modalStyles.checkboxesLabel}
                            >
                              B-Train
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item={true} xs={6} sm={6} md={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={postingAttributes.vented}
                              onChange={handlePostingAttributesChange}
                              name="vented"
                              sx={modalStyles.svgIcon}
                            />
                          }
                          label={
                            <Typography
                              variant="body1"
                              sx={modalStyles.checkboxesLabel}
                            >
                              Vented
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item={true} xs={6} sm={6} md={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={postingAttributes.expedite}
                              onChange={handlePostingAttributesChange}
                              name="expedite"
                              sx={modalStyles.svgIcon}
                            />
                          }
                          label={
                            <Typography
                              variant="body1"
                              sx={modalStyles.checkboxesLabel}
                            >
                              Expedite
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item={true} xs={6} sm={6} md={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={postingAttributes.blanketWrap}
                              onChange={handlePostingAttributesChange}
                              name="blanketWrap"
                              sx={modalStyles.svgIcon}
                            />
                          }
                          label={
                            <Typography
                              variant="body1"
                              sx={modalStyles.checkboxesLabel}
                            >
                              Blanket Wrap
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item={true} xs={6} sm={6} md={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={postingAttributes.triAxle}
                              onChange={handlePostingAttributesChange}
                              name="triAxle"
                              sx={modalStyles.svgIcon}
                            />
                          }
                          label={
                            <Typography
                              variant="body1"
                              sx={modalStyles.checkboxesLabel}
                            >
                              Tri-Axle
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item={true} xs={6} sm={6} md={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={postingAttributes.inbound}
                              onChange={handlePostingAttributesChange}
                              name="inbound"
                              sx={modalStyles.svgIcon}
                            />
                          }
                          label={
                            <Typography
                              variant="body1"
                              sx={modalStyles.checkboxesLabel}
                            >
                              Inbound
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item={true} xs={6} sm={6} md={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={postingAttributes.chains}
                              onChange={handlePostingAttributesChange}
                              name="chains"
                              sx={modalStyles.svgIcon}
                            />
                          }
                          label={
                            <Typography
                              variant="body1"
                              sx={modalStyles.checkboxesLabel}
                            >
                              Chains
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item={true} xs={6} sm={6} md={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={postingAttributes.team}
                              onChange={handlePostingAttributesChange}
                              name="team"
                              sx={modalStyles.svgIcon}
                            />
                          }
                          label={
                            <Typography
                              variant="body1"
                              sx={modalStyles.checkboxesLabel}
                            >
                              Team
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item={true} xs={6} sm={6} md={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={postingAttributes.hazMat}
                              onChange={handlePostingAttributesChange}
                              name="hazMat"
                              sx={modalStyles.svgIcon}
                            />
                          }
                          label={
                            <Typography
                              variant="body1"
                              sx={modalStyles.checkboxesLabel}
                            >
                              HazMat
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item={true} xs={6} sm={6} md={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={postingAttributes.tailgate}
                              onChange={handlePostingAttributesChange}
                              name="tailgate"
                              sx={modalStyles.svgIcon}
                            />
                          }
                          label={
                            <Typography
                              variant="body1"
                              sx={modalStyles.checkboxesLabel}
                            >
                              Tailgate
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item={true} xs={6} sm={6} md={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={postingAttributes.insulated}
                              onChange={handlePostingAttributesChange}
                              name="insulated"
                              sx={modalStyles.svgIcon}
                            />
                          }
                          label={
                            <Typography
                              variant="body1"
                              sx={modalStyles.checkboxesLabel}
                            >
                              Insulated
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item={true} xs={6} sm={6} md={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={postingAttributes.frozen}
                              onChange={handlePostingAttributesChange}
                              name="frozen"
                              sx={modalStyles.svgIcon}
                            />
                          }
                          label={
                            <Typography
                              variant="body1"
                              sx={modalStyles.checkboxesLabel}
                            >
                              Frozen
                            </Typography>
                          }
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </FormControl>
                {/* Comments */}
                <FormLabel htmlFor="comments" sx={modalStyles.modalFormLabels}>
                  Comments
                </FormLabel>
                <TextField
                  name="comments"
                  defaultValue=""
                  id="comments"
                  multiline
                  rows={4}
                  fullWidth
                  sx={modalStyles.modalFormFields}
                />
              </Box>
            </Grid>
            {isSmallScreen && (
              <Grid item xs={12}>
                <Box mt={1} />
              </Grid>
            )}
            <Grid item={true} xs={12} sm={12} md={6}>
              <Box sx={viewPost.mapBoxBox}>
                <MapCan
                  originName={originName}
                  destinationName={destinationName}
                />
              </Box>
            </Grid>
            {isSmallScreen && (
              <Grid item xs={12}>
                <Box mt={1} />
              </Grid>
            )}
            <Button
              type="submit"
              variant="contained"
              sx={modalStyles.submitButton}
              onClick={handleSubmit}
            >
              Add
            </Button>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default CreatePost;
