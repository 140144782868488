import React from "react";

const Delete = ({ color }) => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.00781 4.97705H5.0099V12.9938H4.00781V4.97705Z"
        fill={color || "#142953"}
      />
      <path
        d="M6.01172 4.97705H7.01381V12.9938H6.01172V4.97705Z"
        fill={color || "#142953"}
      />
      <path
        d="M8.01758 4.97705H9.01967V12.9938H8.01758V4.97705Z"
        fill={color || "#142953"}
      />
      <path d="M0 1.9707H13.0271V2.97279H0V1.9707Z" fill={color || "#142953"} />
      <path
        d="M8.98464 2.47181H8.04937V1.46973C8.04937 1.1691 7.81554 0.935283 7.51492 0.935283H5.51074C5.21012 0.935283 4.97629 1.1691 4.97629 1.46973V2.47181H4.04102V1.46973C4.04102 0.668056 4.70907 0 5.51074 0H7.51492C8.31659 0 8.98464 0.668056 8.98464 1.46973V2.47181Z"
        fill={color || "#142953"}
      />
      <path
        d="M9.5197 16.0001H3.50717C2.7055 16.0001 2.00404 15.332 1.93724 14.5303L1.00195 2.50528L2.00404 2.43848L2.93932 14.4635C2.97273 14.7642 3.23995 14.998 3.50717 14.998H9.5197C9.82033 14.998 10.0875 14.7308 10.0875 14.4635L11.0228 2.43848L12.0249 2.50528L11.0896 14.5303C11.0228 15.3654 10.3214 16.0001 9.5197 16.0001Z"
        fill={color || "#142953"}
      />
    </svg>
  );
};

export default Delete;
