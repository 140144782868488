export const styles = {
  mapContainer: {
    width: "100%",
    height: "80vh",
    borderRadius: "10px",
  },
  mapStyle: "mapbox://styles/mapbox/dark-v9?optimize=true",
};

// Define the styles for mobile screens
export const mobileStyles = {
  mapContainer: {
    width: "100% !important",
    height: "50vh !important",
    borderRadius: "10px",
  },
};

export const responsiveStyles = {
  ...styles,
  mapContainer: {
    ...styles.mapContainer,
    "@media (maxWidth: 600px)": {
      ...mobileStyles.mapContainer,
    },
  },

  durationContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "10px",
    left: "50%",
    width: "70%",
    // height: "5%",
    height: { xs: "6%", sm: "6%", md: "5%", lg: "5%" },
    transform: "translateX(-50%)",
    backgroundColor: "#222745",
    border: "1.25px solid #fff",
    borderRadius: "5px",
    color: "#fff",
    fontFamily: "Inter",
    fontSize: "14px",
    padding: "0.1% 0.3%",
    textAlign: "center",
    zIndex: "1",
  },
};
