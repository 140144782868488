import { useState } from "react";
import axios from "axios";
import {
  Link,
  IconButton,
  InputAdornment,
  TextField,
  FormControlLabel,
  Button,
  CssBaseline,
  Grid,
  Checkbox,
  Box,
  Typography,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { componentStyles, toastOptions } from "../componentStyles.js";
import "../componentStyles.css";
import { Formik } from "formik";
import { signUpSchema } from "../../schemas";
import { toast } from "react-toastify";

export default function RegistrationForm() {
  const theme = createTheme();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formCheckbox, setFormCheckbox] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCheckboxChange = (event) => {
    setFormCheckbox(event.target.checked);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Grid container component="main" sx={{ height: "100vh" }}>
          <CssBaseline />
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                mx: 4,
                paddingLeft: 4,
                paddingRight: 4,
              }}
            >
              <Typography
                variant="h1"
                fontSize={componentStyles.font50Px}
                style={componentStyles.pageHeading}
              >
                Create an account
              </Typography>
              <Typography
                variant="subtitle1"
                fontSize={componentStyles.font24Px}
                style={componentStyles.pageSubHeading}
              >
                Please Enter Your Details
              </Typography>
              <Formik
                validationSchema={signUpSchema}
                initialValues={{
                  fullName: "",
                  email: "",
                  password: "",
                  confirmPassword: "",
                }}
                onSubmit={async (values) => {
                  if (formCheckbox) {
                    try {
                      const { fullName, email, password } = values;
                      const response = await axios.post(
                        `${process.env.REACT_APP_BACKEND_URL}/auth/sign-up`,
                        { fullName, email, password },
                        { headers: { "Content-Type": "application/json" } }
                      );
                      // Clear the form fields on successful submission
                      values.fullName = "";
                      values.email = "";
                      values.password = "";
                      values.confirmPassword = "";
                      if (response?.data?.status) {
                        toast.success(
                          "Please check your email and verify to continue.",
                          componentStyles.toastOptions
                        );
                      }
                      if (!response?.data?.status) {
                        toast.error(
                          response.data.message,
                          componentStyles.toastOptions
                        );
                      }
                    } catch (err) {
                      console.log("error", err);
                      toast.error(
                        err.response.data.message,
                        componentStyles.toastOptions
                      );
                    }
                  } else {
                    toast.error(
                      "Please check the 'Terms & Conditions' Checkbox to continue.",
                      componentStyles.toastOptions
                    );
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <div>
                    <Box sx={{ mt: 2 }}>
                      <form noValidate onSubmit={handleSubmit}>
                        <TextField
                          fullWidth
                          id="fullName"
                          label="Enter Name"
                          name="fullName"
                          type="text"
                          autoComplete="off"
                          value={values.fullName}
                          onChange={handleChange}
                          error={touched.fullName && Boolean(errors.fullName)}
                          helperText={touched.fullName && errors.fullName}
                          onBlur={handleBlur}
                          sx={componentStyles.customTextField9px}
                        />
                        <TextField
                          fullWidth
                          id="email"
                          label="Enter Email"
                          name="email"
                          type="email"
                          autoComplete="off"
                          value={values.email}
                          onChange={handleChange}
                          error={touched.email && Boolean(errors.email)}
                          helperText={touched.email && errors.email}
                          onBlur={handleBlur}
                          sx={componentStyles.customTextField9px}
                        />
                        <TextField
                          fullWidth
                          name="password"
                          label="Enter Password"
                          type={showPassword ? "text" : "password"}
                          id="password"
                          autoComplete="off"
                          value={values.password}
                          onChange={handleChange}
                          error={touched.password && Boolean(errors.password)}
                          helperText={touched.password && errors.password}
                          onBlur={handleBlur}
                          sx={componentStyles.customTextField9px}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          fullWidth
                          name="confirmPassword"
                          label="Confirm Password"
                          type={showConfirmPassword ? "text" : "password"}
                          id="confirmPassword"
                          autoComplete="off"
                          value={values.confirmPassword}
                          onChange={handleChange}
                          error={
                            touched.confirmPassword &&
                            Boolean(errors.confirmPassword)
                          }
                          helperText={
                            touched.confirmPassword && errors.confirmPassword
                          }
                          onBlur={handleBlur}
                          sx={componentStyles.customTextField8px}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowConfirmPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showConfirmPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <Grid item xs>
                          <FormControlLabel
                            sx={{
                              fontSize: { xs: "14px", sm: "18px", md: "24px" },
                            }}
                            control={
                              <Checkbox
                                checked={formCheckbox}
                                onChange={handleCheckboxChange}
                                name="formCheckbox"
                                color="primary"
                                required
                              />
                            }
                            label={
                              <p>
                                <span style={{ color: "#212121" }}>
                                  I agree with your{"\u00A0"}
                                </span>
                                <span sx={{ ml: 0.5 }}>
                                  <Link
                                    sx={{
                                      fontWeight: "400",
                                      fontFamily: "Helvetica",
                                      textDecoration: "none",
                                      "&:hover": {
                                        textDecoration: "underline",
                                      },
                                    }}
                                    href="../terms&conditions"
                                    variant="body2"
                                  >
                                    <strong style={{ color: "#212121" }}>
                                      Terms & Conditions
                                    </strong>
                                  </Link>
                                </span>
                              </p>
                            }
                          />
                        </Grid>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          sx={componentStyles.submitButtonReg}
                        >
                          Continue
                        </Button>
                      </form>
                    </Box>
                    <Grid container justifyContent="center">
                      <Grid item>
                        <Typography sx={componentStyles.buttonSubText}>
                          Already Have Account?{"\u00A0"}
                          <Link href="../login" sx={componentStyles.formLinks}>
                            <strong style={{ color: " #212121" }}>
                              Sign In
                            </strong>
                          </Link>
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                )}
              </Formik>
            </Box>
          </Grid>
          <Grid
            sx={{
              display: { xs: "none", sm: "none", md: "flex" },
              justifyContent: "flex-end",
              alignItems: "center",
            }}
            item
            md={6}
            className="rightElement"
          />
        </Grid>
      </ThemeProvider>
    </>
  );
}
