import axios from "axios";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { componentStyles, toastOptions } from "./componentStyles.js";
import "./componentStyles.css";
import { toast } from "react-toastify";
import { Formik } from "formik";
import * as Yup from "yup";

const schema = Yup.object().shape({
  email: Yup.string().email().required("Please enter your email"),
});

export default function ForgotPasswordPage() {
  const theme = createTheme();

  return (
    <>
      <ThemeProvider theme={theme}>
        <Grid container component="main" sx={{ height: "100vh" }}>
          <CssBaseline />
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                mx: 1,
              }}
            >
              <Typography
                variant="h1"
                fontSize={componentStyles.font50Px}
                style={componentStyles.pageHeading}
              >
                Forget Password
              </Typography>
              <Typography
                variant="subtitle1"
                fontSize={componentStyles.font24Px}
                style={componentStyles.pageSubHeading}
              >
                Please Enter Your Email
              </Typography>
              <Formik
                validationSchema={schema}
                initialValues={{ email: "" }}
                onSubmit={async (values) => {
                  try {
                    const { email } = values;
                    const { posted } = await axios.post(
                      `${process.env.REACT_APP_BACKEND_URL}/auth/forgot-password`,
                      {
                        email: email,
                      }
                    );
                    toast.success(
                      "Please check your email to reset your password.",
                      componentStyles.toastOptions
                    );
                  } catch (err) {
                    toast.error(
                      err.response.data.message,
                      componentStyles.toastOptions
                    );
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <div>
                    <Box sx={{ mt: 5, mx: { xs: 1, sm: 1, md: -7 } }}>
                      <form noValidate onSubmit={handleSubmit}>
                        <TextField
                          required
                          fullWidth
                          id="email"
                          label="Enter Email"
                          name="email"
                          type="email"
                          autoComplete="off"
                          value={values.email}
                          onChange={handleChange}
                          error={touched.email && Boolean(errors.email)}
                          helperText={touched.email && errors.email}
                          onBlur={handleBlur}
                          sx={componentStyles.customTextField20px}
                        />
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          sx={componentStyles.submitButton}
                        >
                          Continue
                        </Button>
                      </form>
                    </Box>
                  </div>
                )}
              </Formik>
            </Box>
          </Grid>
          <Grid
            sx={{
              display: { xs: "none", sm: "none", md: "flex" },
              justifyContent: "flex-end",
              alignItems: "center",
            }}
            item
            md={6}
            className="rightElement"
          />
        </Grid>
      </ThemeProvider>
    </>
  );
}
