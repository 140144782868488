import { useState, useContext } from "react";
import axios from "axios";

import {
  IconButton,
  InputAdornment,
  TextField,
  Button,
  Box,
  Typography,
} from "@mui/material";

import { Formik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";

import UserContext from "../../UserContext";
import Edit from "../../assets/icons/Edit";
import { settingStyles, toastOptions } from "./styles";

// Creating schema
const schema = Yup.object().shape({
  fullName: Yup.string().min(2).max(25).required("Please enter your name"),
});

const NameUpdate = () => {
  const { fullName, setFullName } = useContext(UserContext);
  const logedInUser = JSON.parse(localStorage.getItem("logedInUser"));
  const updateFullName = logedInUser.user.fullName;
  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  return (
    <>
      <Formik
        validationSchema={schema}
        initialValues={{
          fullName: updateFullName,
        }}
        onSubmit={async (values) => {
          try {
            const { fullName } = values;
            await schema.validate(values, { abortEarly: false });
            // Compare the input old password with the stored hashed password

            const logedInUser = await JSON.parse(
              localStorage.getItem("logedInUser")
            );
            const token = logedInUser.token;

            var data = JSON.stringify({
              fullName: fullName,
            });

            var config = {
              method: "post",
              url: `${process.env.REACT_APP_BACKEND_URL}/auth/reset-password-profile`,
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              data: data,
            };

            axios(config)
              .then(function (response) {
                setFullName(fullName);
                toast.success(
                  "Successfully reset name.",
                  settingStyles.toastOptions
                );
                setIsEditing(false);
                // Update and clear the form fields on successful submission
                values.fullName = fullName;
              })
              .catch(function (err) {
                toast.error(
                  err?.response?.data?.message
                    ? err.response.data.message
                    : "Something went wrong",
                  settingStyles.toastOptions
                );
              });
          } catch (error) {
            console.error(error);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <div>
            <Box>
              <form noValidate onSubmit={handleSubmit}>
                <Typography variant="body1" sx={settingStyles.formLabels}>
                  Full Name
                </Typography>
                {isEditing ? (
                  <>
                    <TextField
                      fullWidth
                      id="fullName"
                      defaultValue={fullName}
                      name="fullName"
                      type="text"
                      value={values.fullName}
                      onChange={handleChange}
                      error={touched.fullName && Boolean(errors.fullName)}
                      helperText={touched.fullName && errors.fullName}
                      onBlur={handleBlur}
                      autoComplete="off"
                      sx={{
                        ...settingStyles.customTextField9px,
                        mr: "5px",
                      }}
                      InputProps={{
                        readOnly: false,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleEditClick}>
                              <Edit />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      sx={settingStyles.otherButtons}
                    >
                      Save Name
                    </Button>
                  </>
                ) : (
                  <>
                    <TextField
                      fullWidth
                      id="fullName"
                      defaultValue={fullName}
                      name="fullName"
                      type="text"
                      autoComplete="off"
                      sx={settingStyles.customTextField9px}
                      InputProps={{
                        readOnly: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleEditClick}>
                              <Edit color="#5A5A5A" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </>
                )}
              </form>
            </Box>
          </div>
        )}
      </Formik>
    </>
  );
};

export default NameUpdate;
