export const postingStyles = {
  outerContainer: {
    padding: { sm: "1%", md: "3%" },
    paddingTop: { xs: "2%", sm: "2%", md: "6.1%" },
  },

  outerBox: {
    marginTop: { xs: "5px", sm: "5px", md: "30px" },
    marginBottom: { xs: "10px", sm: "10px", md: "25px" },
    display: { sm: "grid", md: "flex" },
    width: "100%",
    justifyContent: { sm: "flex-start", md: "space-between" },
    flexWrap: { sm: "wrap", md: "nowrap" },
    alignItems: "center",
  },

  tableTitle: {
    fontWeight: "bold",
    color: "#142953",
    width: "auto",
    whiteSpace: "nowrap",
  },

  tableFunctions: {
    display: "flex",
    justifyContent: {
      xs: "space-between",
      sm: "space-between",
      md: "flex-end",
      lg: "flex-end",
    },
    flexWrap: { xs: "nowrap", sm: "nowrap", md: "wrap", lg: "wrap" },
    alignItems: "center",
    width: "100%",
  },

  // tableFunctions: {
  //   display: "flex",
  //   flexWrap: { sm: "wrap", md: "wrap", lg: "nowrap" },
  //   alignItems: "center",
  //   width: { xs: "50%", sm: "auto", md: "auto", lg: "auto" },
  // },

  // tableFunctions: {
  //   display: "flex",
  //   justifyContent: {
  //     xs: "space-between",
  //     sm: "space-between",
  //     md: "flex-start",
  //     lg: "flex-start",
  //   },
  //   flexWrap: { xs: "nowrap", sm: "nowrap", md: "wrap", lg: "wrap" },
  //   alignItems: "center",
  //   width: "100%",
  // },

  searchBox: {
    width: "auto",
    marginRight: "1.5%",
  },

  filterButton: {
    backgroundColor: "#142953",
    color: "#FFFFFF",
    width: "auto",
    marginRight: "1.5%",
  },

  addButton: {
    backgroundColor: "#142953",
    color: "#FFFFFF",
    width: "auto",
    marginTop: { sm: "1%", md: "0%" },
  },
};

export const modalStyles = {
  modalOuter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Inter",
  },

  smallStyleBox: {
    width: { sm: "80%", md: "78.6%" },
    maxWidth: { sm: "80%", md: "78.6%" },
    height: "100%",
    overflow: "scroll",
    backgroundColor: "#fff",
    borderRadius: "10px",
    padding: { xs: "2.5%", sm: "2.5%", md: "0%" },
  },

  boxOuter: {
    width: { sm: "80%", md: "78.6%" },
    maxWidth: { sm: "80%", md: "78.6%" },
    height: { sm: "80%", md: "95%" },
    maxHeight: { sm: "90%", md: "95%" },
    overflow: "scroll",
    backgroundColor: "#fff",
    borderRadius: "10px",
    padding: { xs: "2.5%", sm: "2.5%", md: "0%" },
  },

  // modalRoot: {
  //   "& .MuiBox-root.css-1jupfhd": {
  //     "@media (max-width: 600px)": {
  //       height: "100%",
  //     },
  //   },
  // },

  boxTitle: {
    display: "flex",
    alignItems: "center",
    zIndex: 999,
    justifyContent: "center",
    backgroundColor: "#142953",
    borderRadius: "10px 10px 0 0",
    padding: { sm: "4px", md: "6px", lg: "10px" },
    marginBottom: { sm: "4px", md: "6px", lg: "10px" },
    position: "sticky",
    top: "0%",
  },

  textTitle: {
    color: "#fff",
    flexGrow: 1,
    textAlign: "center",
    fontWeight: "500",
    fontSize: { sm: "16px", md: "20px", lg: "24px" },
  },

  svgIcon: {
    color: "#142953",
    "& .MuiSvgIcon-root": { color: "#142953" },
  },

  modalFormLabels: {
    color: "#142953",
    fontWeight: "500",
    fontSize: { sm: "12px", md: "14px", lg: "16px" },
    // marginBottom: { xs: "1%", sm: "1%", md: "2%" },
  },

  modalFormFields: {
    "& .MuiOutlinedInput-root": {
      "& > fieldset": {
        borderRadius: "4px",
        border: "1px solid #142953",
      },
      "&.Mui-focused > fieldset": {
        borderColor: "#142953",
      },
    },
  },

  modalFormFieldsDate: {
    "& .MuiOutlinedInput-root": {
      "& > fieldset": {
        borderRadius: "4px",
        border: "1px solid #142953",
      },
    },
  },

  dateHeight: {
    height: "40px",
  },

  modalMapFormFields: {
    "& .MuiOutlinedInput-root": {
      "& > fieldset": {
        borderRadius: "4px",
        border: "1px solid #142953",
        opacity: "0.8",
      },
    },
  },

  checkboxesBox: {
    // display: "inline-flex",
    display: "inline-flex",
    width: "100%",
    height: "50%",
    flexDirection: "row",
    // flexDirection: "column",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },

  checkboxesLabel: {
    fontSize: { sm: "10px", md: "13px", lg: "15px" },
  },

  checkboxesFormControl: {
    width: "100%",
    display: "flex",
  },

  submitButton: {
    backgroundColor: "#142953",
    color: "#FFFFFF",
    my: { sm: "1.5%", md: "0%" },
    mx: { sm: "1%", md: "1.7%" },
    width: { xs: "100%", sm: "100%", md: "auto", lg: "auto" },
  },
};

export const viewPost = {
  boxSection1: {
    padding: "3%",
  },
  boxSection2: {
    padding: "3%",
  },
  fieldTitles: {
    color: "#142953",
    fontWeight: "500",
    fontFamily: "Inter",
  },
  fieldContent: {
    color: "#212121",
    fontWeight: "400",
    fontFamily: "Inter",
    mb: "3%",
  },
  boxSelectedCheckboxes: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  selectedCheckboxes: {
    backgroundColor: "#142953",
    borderRadius: "5px",
    color: "#FFFFFF",
    fontWeight: "400",
    fontFamily: "Inter",
    padding: "2%",
    mr: "1.5%",
    mb: "3%",
  },
  mapBoxBox: {
    padding: { sm: "1%", md: "3%" },
    position: { sm: "relative", md: "sticky" },
    top: { sm: "1%", md: "13%" },
  },
};

export const toastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
  zIndex: 9999,
};
