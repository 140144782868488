import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
} from "@mui/material";

const ConfirmationDialog = ({ open, onClose, onActionConfirm, action }) => {
  const isEnableAction = action === "Enable User";
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent
        sx={{
          padding: "5%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <DialogContentText
          sx={{
            textAlign: "center",
            color: "#142953",
            fontWeight: "500",
            fontFamily: "Inter",
            fontSize: { sm: "14px", md: "16px", lg: "20px" },
          }}
        >
          Are you sure you want to {action}?
        </DialogContentText>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Button
            onClick={onActionConfirm}
            size="small"
            style={{
              backgroundColor: isEnableAction ? "#142953" : "#FF0000",
              color: "#fff",
              marginRight: "20%",
            }}
          >
            {action}
          </Button>
          <Button
            onClick={onClose}
            size="small"
            style={{
              backgroundColor: isEnableAction ? "#FF0000" : "#142953",
              color: "#fff",
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmationDialog;
