import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "@fontsource/inter";
import "@fontsource/poppins";
import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Users from "./pages/Users";
import Dashboard from "./pages/Dashboard";
import Settings from "./pages/SettingsPage";
import Load from "./pages/Load";
import Equipment from "./pages/Equipment";
import TermsConditions from "./pages/Terms&Conditions/Terms&Conditions";
import PrivacyPolicy from "./pages/Terms&Conditions/PrivacyPolicy";
import Login from "./components/Login/LoginForm";
import RegistrationForm from "./components/Register/RegistrationForm";
import RegistrationDetailsForm from "./components/Register/RegistrationDetailsForm";
import ForgotPasswordPage from "./components/forgotPassword";
import ResetPasswordPage from "./components/resetPassword";
import LoadResultsTable from "./components/Posting/LoadResultsTable";
import EquipmentResultsTable from "./components/Posting/EquipmentResultsTable";

import AdminRoute from "./AdminRoute";
import PrivateRoute from "./PrivateRoute";
import { UserProvider } from "./UserContext";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";

/* eslint-disable import/no-webpack-loader-syntax */
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

ReactDOM.render(
  <UserProvider>
    <BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Routes>
        <Route path="/terms&conditions" element={<TermsConditions />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<RegistrationForm />} />
        <Route path="/register-details" element={<RegistrationDetailsForm />} />
        <Route path="/" element={<App />}>
          {/* <AdminRoute path="/users" element={<Users />} isAdmin="1" />*/}
          {/* <AdminRoute path="/users" element={<Users />} /> */}
          <Route path="users" element={<AdminRoute component={Users} />} />
          <Route
            path="dashboard"
            element={
              <PrivateRoute component={Dashboard} restrictedTo={[true]} />
            }
          />
          <Route
            path="load"
            element={<PrivateRoute component={Load} restrictedTo={[true]} />}
          />
          <Route
            path="load/results"
            element={
              <PrivateRoute
                component={LoadResultsTable}
                restrictedTo={[true]}
              />
            }
          />
          <Route
            path="equipment"
            element={
              <PrivateRoute component={Equipment} restrictedTo={[true]} />
            }
          />
          <Route
            path="equipment/results"
            element={
              <PrivateRoute
                component={EquipmentResultsTable}
                restrictedTo={[true]}
              />
            }
          />
          <Route
            path="settings"
            element={
              <PrivateRoute component={Settings} restrictedTo={[true]} />
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
    ,
  </UserProvider>,
  document.getElementById("root")
);
