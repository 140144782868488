import { useState } from "react";
import axios from "axios";
import {
  TextField,
  FormControl,
  FormLabel,
  Button,
  Select,
  FormHelperText,
  MenuItem,
  CssBaseline,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { componentStyles, toastOptions } from "../componentStyles.js";
import "../componentStyles.css";
import SuccessModal from "./successModal";
import { Formik } from "formik";
import { signUpDetailsSchema } from "../../schemas";
import { toast } from "react-toastify";

export default function RegistrationDetailsForm() {
  const [isSuccess, setIsSuccess] = useState(false);
  const logedInUser = JSON.parse(localStorage.getItem("logedInUser"));
  const fullName = logedInUser.user.fullName;
  const email = logedInUser.user.email;
  const theme = createTheme();

  const isSuccessOpen = () => {
    setIsSuccess(true);
  };
  const isSuccessClose = () => {
    setIsSuccess(false);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Grid container component="main" sx={{ height: "100vh" }}>
          <CssBaseline />
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                mx: 4,
                paddingLeft: 4,
                paddingRight: 4,
              }}
            >
              <Typography
                fontSize={componentStyles.font24Px}
                style={componentStyles.pageHeadingAlt}
              >
                User Information
              </Typography>
              <Formik
                validationSchema={signUpDetailsSchema}
                initialValues={{
                  fullName: fullName,
                  company: "",
                  website: "",
                  address: "",
                  country: "",
                  state: "",
                  city: "",
                  zip: "",
                  number: "",
                  email: email,
                }}
                onSubmit={async (values) => {
                  try {
                    const token = logedInUser.token;
                    const {
                      fullName,
                      company,
                      website,
                      address,
                      country,
                      state,
                      city,
                      zip,
                      number,
                      email,
                    } = values;

                    const response = await axios.post(
                      `${process.env.REACT_APP_BACKEND_URL}/auth/adminVerification`,
                      {
                        fullName,
                        companyName: company,
                        website,
                        address,
                        country,
                        state,
                        city,
                        zipcode: zip,
                        contactno: number,
                        email,
                      },
                      {
                        headers: {
                          Authorization: `Bearer ${token}`,
                          "Content-Type": "application/json",
                        },
                      }
                    );

                    // Clear the form fields on successful submission
                    values.fullName = "";
                    values.company = "";
                    values.website = "";
                    values.address = "";
                    values.country = "";
                    values.state = "";
                    values.city = "";
                    values.zip = "";
                    values.number = "";
                    values.email = "";

                    if (response?.data?.status) {
                      setIsSuccess((prevState) => !prevState);
                    }
                    if (!response?.data?.status) {
                      toast.error(
                        response.data.message,
                        componentStyles.toastOptions
                      );
                    }
                  } catch (err) {
                    console.log("error", err);
                    toast.error(
                      err.response.data.message,
                      componentStyles.toastOptions
                    );
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <div>
                    <Box sx={componentStyles.boxDetails}>
                      <form noValidate onSubmit={handleSubmit}>
                        <FormLabel
                          htmlFor="fullName"
                          sx={componentStyles.formLabels}
                        >
                          Full Name
                        </FormLabel>
                        <TextField
                          fullWidth
                          id="fullName"
                          name="fullName"
                          type="text"
                          autoComplete="off"
                          size="small"
                          defaultValue={fullName}
                          onChange={handleChange}
                          error={touched.fullName && Boolean(errors.fullName)}
                          helperText={touched.fullName && errors.fullName}
                          onBlur={handleBlur}
                          sx={componentStyles.formFields}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                        <FormControl
                          sx={{ width: { xs: "100%", sm: "48%" } }}
                          required
                        >
                          <FormLabel
                            htmlFor="company"
                            sx={componentStyles.formLabels}
                          >
                            Company Name
                          </FormLabel>
                          <TextField
                            id="company"
                            name="company"
                            size="small"
                            value={values.company}
                            onChange={handleChange}
                            error={touched.company && Boolean(errors.company)}
                            helperText={touched.company && errors.company}
                            onBlur={handleBlur}
                            sx={componentStyles.formFields}
                          />
                        </FormControl>
                        <FormControl
                          sx={{
                            ml: { xs: 0, sm: "4%" },
                            width: { xs: "100%", sm: "48%" },
                          }}
                        >
                          <FormLabel
                            htmlFor="website"
                            sx={componentStyles.formLabels}
                          >
                            Website
                          </FormLabel>
                          <TextField
                            id="website"
                            name="website"
                            size="small"
                            value={values.website}
                            onChange={handleChange}
                            error={touched.website && Boolean(errors.website)}
                            helperText={touched.website && errors.website}
                            onBlur={handleBlur}
                            sx={componentStyles.formFields}
                          />
                        </FormControl>
                        <FormControl sx={{ width: "100%" }} required>
                          <FormLabel
                            htmlFor="address"
                            sx={componentStyles.formLabels}
                          >
                            Company Address
                          </FormLabel>
                          <TextField
                            fullWidth
                            id="address"
                            name="address"
                            type="text"
                            autoComplete="off"
                            size="small"
                            value={values.address}
                            onChange={handleChange}
                            error={touched.address && Boolean(errors.address)}
                            helperText={touched.address && errors.address}
                            onBlur={handleBlur}
                            sx={componentStyles.formFields}
                          />
                        </FormControl>
                        <FormControl
                          sx={{
                            width: { xs: "100%", sm: "48%" },
                          }}
                        >
                          <FormLabel
                            htmlFor="country"
                            sx={componentStyles.formLabels}
                          >
                            Country
                          </FormLabel>
                          <Select
                            id="country"
                            name="country"
                            size="small"
                            value={values.country}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.country && Boolean(errors.country)}
                            sx={componentStyles.formSelectFields}
                          >
                            <MenuItem value="Canada">Canada</MenuItem>
                            <MenuItem value="USA">USA</MenuItem>
                          </Select>
                          {touched.country && errors.country && (
                            <FormHelperText error>
                              {errors.country}
                            </FormHelperText>
                          )}
                        </FormControl>
                        <FormControl
                          sx={{
                            ml: { xs: 0, sm: "4%" },
                            width: { xs: "100%", sm: "48%" },
                          }}
                          required
                        >
                          <FormLabel
                            htmlFor="state"
                            sx={componentStyles.formLabels}
                          >
                            Province / State
                          </FormLabel>
                          <TextField
                            id="state"
                            name="state"
                            size="small"
                            value={values.state}
                            onChange={handleChange}
                            error={touched.state && Boolean(errors.state)}
                            helperText={touched.state && errors.state}
                            onBlur={handleBlur}
                            sx={componentStyles.formFields}
                          />
                        </FormControl>
                        <FormControl
                          sx={{
                            width: { xs: "100%", sm: "48%" },
                          }}
                        >
                          <FormLabel
                            htmlFor="city"
                            sx={componentStyles.formLabels}
                          >
                            City
                          </FormLabel>
                          <TextField
                            id="city"
                            name="city"
                            size="small"
                            value={values.city}
                            onChange={handleChange}
                            error={touched.city && Boolean(errors.city)}
                            helperText={touched.city && errors.city}
                            onBlur={handleBlur}
                            sx={componentStyles.formFields}
                          />
                        </FormControl>
                        <FormControl
                          sx={{
                            ml: { xs: 0, sm: "4%" },
                            width: { xs: "100%", sm: "48%" },
                          }}
                          required
                        >
                          <FormLabel
                            htmlFor="zip"
                            sx={componentStyles.formLabels}
                          >
                            Postal / Zip Code
                          </FormLabel>
                          <TextField
                            id="zip"
                            name="zip"
                            size="small"
                            value={values.zip}
                            onChange={(event) => {
                              const inputValue = event.target.value;
                              let formattedValue = inputValue
                                .toUpperCase()
                                .replace(/[^A-Z0-9-]/gi, "");
                              if (
                                /\d/g.test(formattedValue) &&
                                !formattedValue.includes("-")
                              ) {
                                formattedValue = formattedValue
                                  .replace(/(.{3})/g, "$1 ")
                                  .replace(/(\d{2}) /g, "$1");
                              }
                              handleChange({
                                target: {
                                  name: "zip",
                                  value: formattedValue.trim(),
                                },
                              });
                            }}
                            error={touched.zip && Boolean(errors.zip)}
                            helperText={touched.zip && errors.zip}
                            onBlur={handleBlur}
                            sx={componentStyles.formFields}
                          />
                        </FormControl>
                        <FormControl
                          sx={{
                            width: { xs: "100%", sm: "48%" },
                          }}
                        >
                          <FormLabel
                            htmlFor="number"
                            sx={componentStyles.formLabels}
                          >
                            Contact Number
                          </FormLabel>
                          <TextField
                            id="number"
                            name="number"
                            size="small"
                            value={values.number}
                            onChange={handleChange}
                            error={touched.number && Boolean(errors.number)}
                            helperText={touched.number && errors.number}
                            onBlur={handleBlur}
                            sx={componentStyles.formFields}
                          />
                        </FormControl>
                        <FormControl
                          sx={{
                            ml: { xs: 0, sm: "4%" },
                            width: { xs: "100%", sm: "48%" },
                          }}
                        >
                          <FormLabel
                            htmlFor="email"
                            sx={componentStyles.formLabels}
                          >
                            Email
                          </FormLabel>
                          <TextField
                            id="email"
                            name="email"
                            size="small"
                            defaultValue={email}
                            onChange={handleChange}
                            error={touched.email && Boolean(errors.email)}
                            helperText={touched.email && errors.email}
                            onBlur={handleBlur}
                            sx={componentStyles.formFields}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </FormControl>
                        <Box sx={componentStyles.buttonBox}>
                          <Button
                            type="submit"
                            variant="contained"
                            sx={componentStyles.submitButtonRegDetails}
                          >
                            Submit
                          </Button>
                        </Box>
                      </form>
                    </Box>
                  </div>
                )}
              </Formik>
            </Box>
          </Grid>
          <Grid
            sx={{
              display: { xs: "none", sm: "none", md: "flex" },
              justifyContent: "flex-end",
              alignItems: "center",
            }}
            item
            md={6}
            className="rightElement"
          />
        </Grid>
      </ThemeProvider>
      <SuccessModal open={isSuccess} onClose={isSuccessClose} />;
    </>
  );
}
