import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Button,
  IconButton,
  useMediaQuery,
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
  TextField,
  InputAdornment,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CheckIcon from "@mui/icons-material/Check";
import { Box } from "@mui/system";
import { toast } from "react-toastify";
import CreatePost from "./CreatePost";
import { postingStyles, toastOptions } from "./styles";
import DeleteConfirmationDialog from "./DeleteConfirmationDialog";
import Search from "../icons/Search";
import Filter from "../icons/Filter";
import Add from "../icons/Add";
import Edit from "../icons/Edit";
import Delete from "../icons/Delete";
import EditLoad from "./EditLoad";

const OwnLoads = () => {
  const isSmallScreen = useMediaQuery("(max-width:500px)");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loadTableData, setLoadTableData] = useState(null);
  const [counter, setCounter] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isMenuItemHovered, setIsMenuItemHovered] = useState(false);
  const [isDeleteMenuItemHovered, setIsDeleteMenuItemHovered] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [row, setRow] = useState();

  const navigate = useNavigate();

  const isAddOpen = () => {
    setIsAdd(true);
  };
  const isAddClose = () => {
    setIsAdd(false);
    setCounter(counter + 1);
  };
  const isEditOpen = () => {
    setActiveItem("edit");
    handleMenuClose();
    setIsEdit(true);
  };
  const isEditClose = () => {
    setIsEdit(false);
    setCounter(counter + 1);
  };

  const logedInUser = JSON.parse(localStorage.getItem("logedInUser"));
  const userId = logedInUser.user.id;

  // TABLE DATA FILTERED WITH REQUIRED DATA IN TABLE
  const fetchLoadTableData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/load/getall/${userId}`
      );
      const data = response.data;
      const filteredData = data.loads.map((load) => {
        const {
          createdAt,
          createdBy,
          type,
          size,
          company,
          originLat,
          originLng,
          originRadius,
          destinationLat,
          destinationLng,
          destinationRadius,
          postingAttributes,
          comments,
          updatedAt,
          userId,
          ...rest
        } = load;

        return rest;
      });

      const now = new Date();
      const updatedFilteredData = filteredData.map((load) => {
        const postDate = new Date(load.loadDate);
        const postAge = now - postDate;

        const minutes = Math.floor(postAge / 60000);
        const hours = Math.floor(postAge / 3600000);
        const days = Math.floor(postAge / 86400000);

        const postAgeString =
          minutes < 60
            ? `${minutes} mins`
            : hours < 24
            ? `${hours} hrs ${minutes % 60} mins`
            : `${days} days ${hours % 24} hrs`;

        const formattedLoadDate = postDate.toLocaleDateString("en-GB");

        const loadStatus = load.status;
        const formattedLoadStatus = loadStatus ? "Open" : "Closed";

        return {
          ...load,
          status: formattedLoadStatus,
          loadDate: formattedLoadDate,
          postAge: postAgeString,
        };
      });

      setLoadTableData(updatedFilteredData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchLoadTableData();
  }, [userId, counter]);

  const searchData = loadTableData?.filter((item) => {
    return searchTerm !== ""
      ? item?.id?.toString().includes(searchTerm.toString())
      : item;
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const columns = [
    { id: "id", label: "Post ID" },
    { id: "origin", label: "Origin" },
    { id: "destination", label: "Destination" },
    { id: "status", label: "Status" },
    { id: "loadDate", label: "Load Date" },
    { id: "postAge", label: "Post Age" },
    { id: "results", label: "Results" },
    { id: "actions", label: "" },
  ];

  const filterOptions = [
    { label: "Open", value: "Open" },
    { label: "Closed", value: "Closed" },
  ];

  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleStatusClick = async (row) => {
    const updatedTableData = [...loadTableData];
    const index = updatedTableData.findIndex((item) => item.id === row.id);
    const newStatus = updatedTableData[index].status === "Open" ? false : true;
    updatedTableData[index].status = newStatus ? "Open" : "Closed";
    setLoadTableData(updatedTableData);

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/load/updateLoadStatus/${row.id}`,
        {
          loadStatus: newStatus,
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleViewClick = (row) => {
    navigate("/load/results", { state: { rowId: { row } } });
  };

  const handleMenuClick = (event, id) => {
    setSelectedId(id);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setSelectedId(null);
    setAnchorEl(null);
  };

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterOptionClick = (event, option) => {
    setSearchTerm(option);
    setAnchorEl(null);
  };

  const handleDeleteConfirmOpen = () => {
    handleMenuClose();
    setDeleteConfirmOpen(true);
  };

  const handleDeleteClick = async (row) => {
    setActiveItem("delete");
    handleMenuClose();

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/load/remove/${row.id}`
      );
      if (response.data.success == true) {
        toast.success("Post deleted successfully!", toastOptions);
        setCounter(counter + 1);
      } else {
        toast.error("Failed to delete post", toastOptions);
      }
    } catch (error) {
      toast.error("Failed to delete post", toastOptions);
      console.error(error);
    }
  };

  return (
    <>
      <Box sx={postingStyles.outerBox}>
        <Typography variant="h5" sx={postingStyles.tableTitle}>
          Your Posted Loads
        </Typography>
        <Box sx={postingStyles.tableFunctions}>
          <TextField
            placeholder="Search ID"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => handleSearchInputChange(e)}
            InputProps={{
              style: {
                height: "37px",
              },
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            sx={postingStyles.searchBox}
          />
          {/* <Button
            variant="contained"
            onClick={handleFilterClick}
            startIcon={<Filter />}
            sx={postingStyles.filterButton}
          >
            Filter
          </Button> */}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            {filterOptions.map((option) => (
              <MenuItem
                key={option.value}
                onClick={(e) => handleFilterOptionClick(e, option.value)}
              >
                <ListItemIcon>
                  {searchTerm === option.value ? (
                    <CheckIcon fontSize="small" />
                  ) : null}
                </ListItemIcon>
                <Typography variant="inherit">{option.label}</Typography>
              </MenuItem>
            ))}
          </Menu>
          <Button
            variant="contained"
            onClick={isAddOpen}
            startIcon={<Add />}
            sx={postingStyles.addButton}
          >
            Add
          </Button>
        </Box>
      </Box>
      <div
        style={{
          overflowX: "auto",
          display: "block",
          width: isSmallScreen ? "305px" : "100%",
        }}
      >
        <TableContainer component={Paper}>
          <Table>
            <TableHead style={{ backgroundColor: "#142953" }}>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align="center"
                    style={{ color: "#FFFFFF", backgroundColor: "#142953" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {searchData?.length === 0 ? (
                <TableRow>
                  <TableCell align="center" colSpan={columns.length}>
                    Use the "ADD" button above to create a load/equipment post.
                  </TableCell>
                </TableRow>
              ) : (
                searchData
                  ?.sort((a, b) => (a.status === "Closed" ? 1 : -1))
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row) => (
                    <TableRow key={row.id}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align="center">
                            {column.id === "status" ? (
                              <Button
                                variant="contained"
                                style={{
                                  backgroundColor:
                                    value === "Open" ? "#142953" : "#FF0000",
                                  color: "#FFFFFF",
                                }}
                                onClick={() => handleStatusClick(row)}
                              >
                                {value}
                              </Button>
                            ) : column.id === "results" ? (
                              <div>
                                {value}
                                <Button
                                  variant="contained"
                                  style={{
                                    backgroundColor: "#142953",
                                    color: "#FFFFFF",
                                  }}
                                  onClick={() => handleViewClick(row.id)}
                                >
                                  View
                                </Button>
                              </div>
                            ) : column.id === "actions" ? (
                              <div>
                                <IconButton
                                  onClick={(e) => handleMenuClick(e, row.id)}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                                <Menu
                                  anchorEl={anchorEl}
                                  open={
                                    Boolean(anchorEl) && selectedId === row.id
                                  }
                                  onClose={handleMenuClose}
                                >
                                  <MenuItem
                                    onClick={() => {
                                      setRow(row);
                                      isEditOpen();
                                    }}
                                    onMouseEnter={() =>
                                      setIsMenuItemHovered(true)
                                    }
                                    onMouseLeave={() =>
                                      setIsMenuItemHovered(false)
                                    }
                                    sx={{
                                      "&:hover": {
                                        backgroundColor: "#142953",
                                        color: "#fff",
                                      },
                                    }}
                                  >
                                    <ListItemIcon>
                                      <Edit
                                        className="editIcon"
                                        color={
                                          isMenuItemHovered ? "#fff" : undefined
                                        }
                                      />
                                    </ListItemIcon>
                                    Edit
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      setRow(row);
                                      setDeleteConfirmOpen(true);
                                    }}
                                    onMouseEnter={() =>
                                      setIsDeleteMenuItemHovered(true)
                                    }
                                    onMouseLeave={() =>
                                      setIsDeleteMenuItemHovered(false)
                                    }
                                    sx={{
                                      "&:hover": {
                                        backgroundColor: "#FF0000",
                                        color: "#fff",
                                      },
                                    }}
                                  >
                                    <ListItemIcon>
                                      <Delete
                                        className="deleteIcon"
                                        color={
                                          isDeleteMenuItemHovered
                                            ? "#fff"
                                            : undefined
                                        }
                                      />
                                    </ListItemIcon>
                                    Delete
                                  </MenuItem>
                                </Menu>
                              </div>
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={searchData?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <CreatePost open={isAdd} handleClose={isAddClose} />
      <EditLoad open={isEdit} handleClose={isEditClose} data={row} />
      <DeleteConfirmationDialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
        onDeleteConfirm={() => {
          handleDeleteClick(row);
          setDeleteConfirmOpen(false);
        }}
      />
    </>
  );
};

export default OwnLoads;
