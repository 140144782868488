import React, { useState, useEffect, useMemo, useRef } from "react";
import { TextField, CircularProgress, Box } from "@mui/material";
import MapboxGeocoding from "@mapbox/mapbox-sdk/services/geocoding";

const Autocomplete = ({
  id,
  value = "",
  onPlaceSelected,
  countryCode,
  mapboxAccessToken,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const [inputRef, setInputRef] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [hoveredSuggestionIndex, setHoveredSuggestionIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const suggestionBoxRef = useRef();

  const client = useMemo(() => {
    return MapboxGeocoding({ accessToken: mapboxAccessToken });
  }, [mapboxAccessToken]);

  useEffect(() => {
    if (inputRef && inputValue) {
      setIsLoading(true);
      client
        .forwardGeocode({
          query: inputValue,
          countries: countryCode.split(","),
          types: ["place"],
          limit: 5,
        })
        .send()
        .then((response) => {
          setSuggestions(response.body.features);
          setIsLoading(false);
        });
    } else {
      setSuggestions([]);
    }
  }, [inputValue, client]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleSuggestionClick = (suggestion) => {
    setInputValue(suggestion.place_name);
    onPlaceSelected(suggestion);
    setIsOpen(false);
  };

  const handleMouseDown = (e) => {
    if (
      suggestionBoxRef.current &&
      !suggestionBoxRef.current.contains(e.target)
    ) {
      setSuggestions([]);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleMouseDown);
    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
    };
  }, []);

  return (
    <Box sx={{ position: "relative" }}>
      <TextField
        id={id}
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value);
          setIsOpen(true);
        }}
        sx={{
          width: "100%",
          "& .MuiOutlinedInput-root": {
            "& > fieldset": {
              borderRadius: "4px",
              border: "1px solid #142953",
              opacity: "0.8",
            },
          },
        }}
        inputProps={{
          style: {
            height: "7px",
          },
        }}
        InputProps={{
          inputRef: setInputRef,
          endAdornment: isLoading && <CircularProgress size={20} />,
        }}
      />
      {isOpen && suggestions.length > 0 && (
        <Box
          ref={suggestionBoxRef}
          sx={{
            position: "absolute",
            zIndex: 10,
            background: "white",
            width: "100%",
            top: "100%",
            border: "1px solid #142953",
          }}
        >
          {suggestions.map((suggestion, index) => (
            <Box
              key={index}
              sx={{
                padding: "5px 10px",
                cursor: "pointer",
                backgroundColor:
                  hoveredSuggestionIndex === index ? "lightgray" : "white",
              }}
              onClick={() => handleSuggestionClick(suggestion)}
              onMouseEnter={() => setHoveredSuggestionIndex(index)}
              onMouseLeave={() => setHoveredSuggestionIndex(null)}
            >
              {suggestion.place_name}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default Autocomplete;
