import { Source, Layer } from "react-map-gl";
import { responsiveStyles } from "./styles";
import Car from "../icons/Car";
import Motorcycle from "../icons/Motorcycle";
import Walk from "../icons/Walk";

export default function Durations({ route, durations }) {
  const formatDuration = (duration) => {
    const hours = Math.floor(duration / 60);
    const minutes = Math.round(duration % 60);

    if (hours > 0) {
      return `${hours} hr ${minutes} min`;
    }
    return `${minutes} min`;
  };

  return (
    <div>
      {durations.driving !== null &&
        durations.cycling !== null &&
        durations.walking !== null && (
          <div style={responsiveStyles.durationContainer}>
            <p style={{ marginRight: "3%" }}>
              <Car /> {formatDuration(durations.driving / 60)}
            </p>
            <p style={{ marginRight: "3%" }}>
              <Motorcycle /> {formatDuration(durations.cycling / 60)}
            </p>
            <p style={{ marginRight: "3%" }}>
              <Walk /> {formatDuration(durations.walking / 60)}
            </p>
          </div>
        )}
      {route &&
        route.geometry &&
        route.geometry.coordinates &&
        route.geometry.coordinates.length > 0 && (
          <Source id="route" type="geojson" data={route}>
            <Layer
              id="route"
              type="line"
              source="route"
              layout={{
                "line-join": "round",
                "line-cap": "round",
              }}
              paint={{
                "line-color": "#6B90DB",
                "line-width": 4,
              }}
            />
          </Source>
        )}
    </div>
  );
}
