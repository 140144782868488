import React from "react";

const Walk = ({ color }) => {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.30523 5.34783C1.00489 4.90987 0.315509 4.64859 2.7338 2.73913L3.37666 2.95652M7.01953 5.34783C5.78195 4.99171 4.72356 5.04345 4.01951 3.17391L3.37666 2.95652M3.37666 2.95652L3.16237 6.65217M3.16237 6.65217L3.16239 7.08696C3.16239 7.08696 3.10335 11.4931 1.01953 10.5652M3.16237 6.65217C3.16237 6.65217 4.04717 7.06418 4.44809 7.52174C5.34455 8.54488 4.87666 11 4.87666 11M3.59096 1H5.30525"
        stroke={color || "white"}
        // fill={color || "white"}
      />
    </svg>
  );
};

export default Walk;
