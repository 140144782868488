import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  useMediaQuery,
  Grid,
  Paper,
  Button,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { postingStyles } from "./styles";
import ViewLoad from "./ViewLoad";

const EquipmentResultsTable = () => {
  const isSmallScreen = useMediaQuery("(max-width:500px)");
  const [equipmentTableData, setEquipmentTableData] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [counter, setCounter] = useState(1);
  const [isView, setIsView] = useState(false);
  const [row, setRow] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const location = useLocation();

  const postID = parseInt(location?.state?.rowId?.row);

  const logedInUser = JSON.parse(localStorage.getItem("logedInUser"));
  const userName = logedInUser.user.fullName;

  const isViewOpen = () => {
    setIsView(true);
  };
  const isViewClose = () => {
    setIsView(false);
  };

  // TABLE DATA FILTERED WITH REQUIRED DATA IN TABLE
  const fetchEquipmentTableData = async (postId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/equipment/getLoadWithinRange/${postId}`
      );

      const data = response.data;

      const filteredData = data.load.map((load) => {
        const {
          createdAt,
          type,
          originRadius,
          destinationRadius,
          postingAttributes,
          comments,
          updatedAt,
          userId,
          ...rest
        } = load;

        return rest;
      });

      const now = new Date();
      const updatedFilteredData = filteredData
        .filter((load) => load.status && load.createdBy !== userName) // filter out rows where loadStatus is false
        .map((load) => {
          const postDate = new Date(load.loadDate);
          const postAge = now - postDate;

          const minutes = Math.floor(postAge / 60000);
          const hours = Math.floor(postAge / 3600000);
          const days = Math.floor(postAge / 86400000);

          const postAgeString =
            minutes < 60
              ? `${minutes} mins`
              : hours < 24
              ? `${hours} hrs ${minutes % 60} mins`
              : `${days} days ${hours % 24} hrs`;

          const formattedLoadDate = postDate.toLocaleDateString("en-GB");

          const sizeString =
            load.size === "full"
              ? "Full TL"
              : load.size === "less"
              ? "Less Than TL"
              : load.size === "both"
              ? "Both"
              : "";

          return {
            ...load,
            size: sizeString,
            loadDate: formattedLoadDate,
            postAge: postAgeString,
          };
        });

      setEquipmentTableData(updatedFilteredData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchEquipmentTableData(postID);
  }, [counter]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const columns = [
    { id: "id", label: "Post ID" },
    { id: "origin", label: "Origin" },
    { id: "destination", label: "Destination" },
    { id: "size", label: "Size" },
    { id: "company", label: "Company" },
    { id: "loadDate", label: "Load Date" },
    { id: "postAge", label: "Post Age" },
    { id: "contactno", label: "Call" },
    { id: "email", label: "Email" },
    { id: "details", label: "Details" },
  ];

  return (
    <>
      <Grid item xs>
        <Box
          sx={{
            my: 2,
            mx: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid sx={postingStyles.outerContainer}>
            <Box sx={postingStyles.outerBox}>
              <Typography variant="h5" sx={postingStyles.tableTitle}>
                Results
              </Typography>
            </Box>
            <div
              style={{
                overflowX: "auto",
                display: "block",
                width: isSmallScreen ? "305px" : "100%",
              }}
            >
              <TableContainer component={Paper}>
                <Table>
                  <TableHead style={{ backgroundColor: "#142953" }}>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align="center"
                          style={{
                            color: "#FFFFFF",
                            backgroundColor: "#142953",
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {equipmentTableData?.length === 0 ? (
                      <TableRow>
                        <TableCell align="center" colSpan={columns.length}>
                          No load found within the radius of your equipment.
                        </TableCell>
                      </TableRow>
                    ) : (
                      equipmentTableData?.map((row) => (
                        <TableRow key={row.id}>
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align="center">
                                {column.id === "details" ? (
                                  <div>
                                    {value}
                                    <Button
                                      variant="contained"
                                      style={{
                                        backgroundColor: "#142953",
                                        color: "#FFFFFF",
                                      }}
                                      onClick={() => {
                                        setRow(row);
                                        isViewOpen();
                                      }}
                                    >
                                      View
                                    </Button>
                                  </div>
                                ) : (
                                  value
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={equipmentTableData?.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          </Grid>
        </Box>
      </Grid>

      <ViewLoad open={isView} handleClose={isViewClose} data={row} />
    </>
  );
};

export default EquipmentResultsTable;
