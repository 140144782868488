import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Button,
  useMediaQuery,
  Typography,
  TextField,
  InputAdornment,
} from "@mui/material";

import { toast } from "react-toastify";

import { postingStyles, toastOptions } from "../Posting/styles";
// import DeleteConfirmationDialog from "./DeleteConfirmationDialog";  Make disable confirm dialog
import ConfirmationDialog from "./ConfirmationDialog";
import Search from "../icons/Search";

const AllUsers = () => {
  const isSmallScreen = useMediaQuery("(max-width:500px)");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [userTableData, setUserTableData] = useState(null);
  const [counter, setCounter] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  // Enable and disable users states
  const [currentAction, setCurrentAction] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [disableConfirmOpen, setDisableConfirmOpen] = useState(false);
  const [enableConfirmOpen, setEnableConfirmOpen] = useState(false);

  const [row, setRow] = useState();

  const navigate = useNavigate();

  const logedInUser = JSON.parse(localStorage.getItem("logedInUser"));
  const userId = logedInUser.user.id;
  const token = logedInUser.token;

  // TABLE DATA FILTERED WITH REQUIRED DATA IN TABLE
  const fetchUserTableData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/auth/get-all-users`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data;
      const filteredData = data.users.map((user) => {
        const {
          website,
          address,
          country,
          state,
          city,
          password,
          isVerified,
          isProfileVerified,
          verificationToken,
          resetToken,
          resetTokenExpiry,
          otp,
          createdAt,
          updatedAt,
          ...rest
        } = user;

        return rest;
      });

      setUserTableData(filteredData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchUserTableData();
  }, [counter]);

  const searchData = userTableData?.filter((item) => {
    return searchTerm !== ""
      ? item?.id?.toString().includes(searchTerm.toString())
      : item;
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const columns = [
    { id: "id", label: "User ID" },
    { id: "fullName", label: "Name" },
    { id: "companyName", label: "Company" },
    { id: "contactno", label: "Contact No." },
    { id: "email", label: "Email" },
    { id: "isDisabled", label: "User Status" },
    { id: "enable", label: "Enable User" },
    { id: "disable", label: "Disable User" },
  ];

  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleEnableUserClick = (row) => {
    setCurrentAction("enable");
    setSelectedRow(row);
    setEnableConfirmOpen(true);
  };

  const handleDisableUserClick = (row) => {
    setCurrentAction("disable");
    setSelectedRow(row);
    setDisableConfirmOpen(true);
  };

  const handleEnablingAndDisablingClick = async (id, action) => {
    const toggleAction = action;
    console.log("action is...", action);
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/auth/toggle-user-status/${id}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Check the response data structure and handle the status accordingly
      if (res.status === 200) {
        const responseData = res.data;

        if (responseData.status === true) {
          toast.success(`User ${toggleAction}d successfully!`, toastOptions);
          setCounter(counter + 1);
        } else {
          toast.error(`Failed to ${toggleAction} user`, toastOptions);
        }
      } else {
        toast.error(`Failed to ${toggleAction} user`, toastOptions);
      }
    } catch (error) {
      toast.error("Something went wrong", toastOptions);
      console.error(error.response);
    }
  };

  return (
    <>
      <Box sx={postingStyles.outerBox}>
        <Typography variant="h5" sx={postingStyles.tableTitle}>
          All Users
        </Typography>
        <Box sx={postingStyles.tableFunctions}>
          <TextField
            placeholder="Search User ID"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => handleSearchInputChange(e)}
            InputProps={{
              style: {
                height: "37px",
              },
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            sx={postingStyles.searchBox}
          />
        </Box>
      </Box>
      <div
        style={{
          overflowX: "auto",
          display: "block",
          width: isSmallScreen ? "305px" : "100%",
        }}
      >
        <TableContainer component={Paper}>
          <Table>
            <TableHead style={{ backgroundColor: "#142953" }}>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align="center"
                    style={{ color: "#FFFFFF", backgroundColor: "#142953" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {searchData?.length === 0 ? (
                <TableRow>
                  <TableCell align="center" colSpan={columns.length}>
                    There are no users to show.
                  </TableCell>
                </TableRow>
              ) : (
                searchData
                  ?.sort((a, b) => (a.status === "Closed" ? 1 : -1))
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row) => (
                    <TableRow key={row.id}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align="center">
                            {column.id === "isDisabled" ? (
                              <Typography variant="body1">
                                {value ? "Disabled" : "Enabled"}
                              </Typography>
                            ) : column.id === "enable" ? (
                              <div>
                                {value}
                                <Button
                                  variant="contained"
                                  style={{
                                    backgroundColor: !row.isDisabled
                                      ? "#5A5A5A"
                                      : "#142953",
                                    color: "#fff",
                                  }}
                                  onClick={() => {
                                    handleEnableUserClick(row);
                                    console.log("Clicked Value:", value);
                                  }}
                                  disabled={!row.isDisabled}
                                >
                                  Enable
                                </Button>
                              </div>
                            ) : column.id === "disable" ? (
                              <div>
                                {value}
                                <Button
                                  variant="contained"
                                  style={{
                                    backgroundColor: !row.isDisabled
                                      ? "#FF0000"
                                      : "#5A5A5A",
                                    color: "#fff",
                                  }}
                                  onClick={() => {
                                    handleDisableUserClick(row);
                                    console.log("Clicked Value:", value);
                                  }}
                                  disabled={row.isDisabled}
                                >
                                  Disable
                                </Button>
                              </div>
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={searchData?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {selectedRow && (
        <>
          {currentAction === "enable" && (
            <ConfirmationDialog
              open={enableConfirmOpen}
              onClose={() => setEnableConfirmOpen(false)}
              onActionConfirm={() => {
                handleEnablingAndDisablingClick(selectedRow.id, "enable");
                console.log("Enabling user:", selectedRow.id);
                setEnableConfirmOpen(false);
              }}
              action="Enable User"
            />
          )}
          {currentAction === "disable" && (
            <ConfirmationDialog
              open={disableConfirmOpen}
              onClose={() => setDisableConfirmOpen(false)}
              onActionConfirm={() => {
                handleEnablingAndDisablingClick(selectedRow.id, "disable");
                console.log("Disabling user:", selectedRow.id);
                setDisableConfirmOpen(false);
              }}
              action="Disable User"
            />
          )}
        </>
      )}
    </>
  );
};

export default AllUsers;
