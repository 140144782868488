import React from "react";

const Search = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.32611 12.6522C2.83789 12.6522 0 9.81433 0 6.32611C0 2.83789 2.83789 0 6.32611 0C9.81433 0 12.6522 2.83789 12.6522 6.32611C12.6522 9.81433 9.81433 12.6522 6.32611 12.6522ZM6.32611 1.26522C3.53566 1.26522 1.26522 3.53566 1.26522 6.32611C1.26522 9.11656 3.53566 11.387 6.32611 11.387C9.11656 11.387 11.387 9.11656 11.387 6.32611C11.387 3.53566 9.11719 1.26522 6.32611 1.26522Z"
        fill="#BFBEBF"
      />
      <path
        d="M15.367 16.0001C15.2839 16.0002 15.2016 15.9839 15.1249 15.9521C15.0481 15.9203 14.9784 15.8736 14.9198 15.8148L10.0524 10.9474C9.99371 10.8887 9.94712 10.819 9.91533 10.7422C9.88355 10.6655 9.86719 10.5833 9.86719 10.5002C9.86719 10.4171 9.88355 10.3349 9.91533 10.2581C9.94712 10.1814 9.99371 10.1117 10.0524 10.0529C10.1112 9.9942 10.1809 9.94761 10.2577 9.91582C10.3344 9.88404 10.4166 9.86768 10.4997 9.86768C10.5828 9.86768 10.665 9.88404 10.7418 9.91582C10.8185 9.94761 10.8882 9.9942 10.947 10.0529L15.8143 14.9202C15.903 15.0086 15.9635 15.1213 15.9881 15.2441C16.0127 15.3669 16.0002 15.4943 15.9523 15.61C15.9044 15.7257 15.8232 15.8245 15.7189 15.894C15.6147 15.9635 15.4922 16.0004 15.367 16.0001Z"
        fill="#BFBEBF"
      />
    </svg>
  );
};

export default Search;
