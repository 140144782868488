export const navbarStyles = {
  drawer: {
    width: 218,
    flexShrink: 0,
    display: { xs: "none", md: "block" },

    "& .MuiDrawer-paper": {
      width: 218,
      boxSizing: "border-box",
      backgroundColor: "#FFFFFF",
      color: "#142953",
      // elevation: "3",
    },
    "& .Mui-selected": {
      color: "#FFFFFF",
      backgroundColor: "#142953",
    },
  },
  icons: {
    color: "#142953",
    fontSize: "2rem",
    display: "flex",
    alignItems: "center",
  },
  text: {
    "& span": {
      justifyContent: "flex-start",
      paddingLeft: "10%",
      lineHeight: "0.7",
      fontWeight: "400",
      fontSize: "1rem",
      fontFamily: "Poppins",
    },
  },
  listItemContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "nowrap",
  },
  logout: {
    marginTop: "auto",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
};

export const navbarMobileStyles = {
  drawer: {
    width: "35%",
    flexShrink: 0,
    display: { xs: "none", md: "block" },

    "& .MuiDrawer-paper": {
      width: "35%",
      boxSizing: "border-box",
      backgroundColor: "#FFFFFF",
      color: "#142953",
    },
    "& .Mui-selected": {
      color: "#FFFFFF",
      backgroundColor: "#142953",
    },
  },
};
