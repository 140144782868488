import * as Yup from "yup";

export const signUpSchema = Yup.object({
  fullName: Yup.string().min(2).max(25).required("Please enter your name"),
  email: Yup.string().email().required("Please enter your email"),
  password: Yup.string().min(6).required("Please enter your password"),
  confirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export const signUpDetailsSchema = Yup.object({
  fullName: Yup.string().min(2).max(25).required("Please enter your name"),
  company: Yup.string().min(2).max(20).required(),
  address: Yup.string().min(2).max(35).required(),
  country: Yup.string()
    .oneOf(["Canada", "USA"], "Please select a valid country")
    .required("Please select your country"),
  state: Yup.string().min(2).max(20).required(),
  city: Yup.string().min(2).max(20).required(),
  zip: Yup.string()
    .matches(
      /^[a-zA-Z0-9- ]{1,10}$/,
      "Zip code should be letters or numbers and not exceed 10 characters"
    )
    .required(),
  number: Yup.string()
    .min(7, "Phone number should be at least 7 digits")
    .max(14, "Phone number should not exceed 14 digits")
    .required(),
  email: Yup.string().email().required("Please enter your email"),
});

export const resetPassword = Yup.object({
  password: Yup.string().min(6).required("Please enter your password"),
  confirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});
