import React, { useState, useContext, useEffect } from "react";
import Navbar from "./components/Navbar/Navbar";
import { UserContext } from "./UserContext";
import NavbarMobile from "./components/Navbar/NavbarMobile";
import useWindowDimensions from "./hooks/useWindowDimensions";
import { Outlet, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { AppBar, Toolbar, Typography, MenuItem, Avatar } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

function App() {
  const { fullName } = useContext(UserContext);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const [isMenuOpen, setIsMenuOpen] = useState(false); // for mobile navbar

  useEffect(() => {
    if (user && "isProfileVerified" in user && !user.isProfileVerified) {
      navigate("/register-details");
    } else if (user && window.location.pathname === "/" && !user?.isAdmin) {
      navigate("/dashboard");
    } else if (user && window.location.pathname === "/" && user?.isAdmin) {
      navigate("/users");
    }
  }, [user, navigate]);

  const handleHamburger = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Grid
      container
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: "flex" }}>
        {width > 900 ? (
          <Box>
            <Navbar />
          </Box>
        ) : (
          <Box>
            <NavbarMobile
              isMenuOpen={isMenuOpen}
              handleHamburger={handleHamburger}
            />
          </Box>
        )}

        <Box
          mt={5}
          sx={{
            flexGrow: 1,
            display: "flex",
          }}
        >
          <AppBar
            sx={{
              backgroundColor: "#142953",
              width: {
                xs: "100%",
                sm: "100%",
                md: "81%",
                lg: "83%",
                xl: "89%",
              },
              margin: "auto",
            }}
          >
            <Toolbar
              sx={{
                textAlign: "center",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <IconButton // Hamburger, only visible on mobile
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleHamburger}
                sx={{
                  mr: 2,
                  backgroundColor: "#142953",
                  display: { md: "none", lg: "none", xl: "none" },
                }}
              >
                <MenuIcon />
              </IconButton>

              <MenuItem
                sx={{
                  justifyContent: "flex-start",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: { xs: "14px", sm: "18px", md: "24px" },
                    fontFamily: "Inter",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Welcome,{"\u00A0"}
                  <Typography
                    sx={{
                      fontWeight: "700",
                      fontSize: { xs: "14px", sm: "18px", md: "24px" },
                      fontFamily: "Inter",
                    }}
                  >
                    {fullName}
                  </Typography>
                  !
                </Typography>
              </MenuItem>

              <MenuItem sx={{ marginLeft: "auto" }}>
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: { xs: "6px", sm: "8px", md: "12px" },
                    fontFamily: "Inter",
                    justifyContent: "flex-end",
                    display: { xs: "none", sm: "none", md: "flex" },
                  }}
                >
                  {user?.isAdmin ? "Administrator" : fullName}
                </Typography>
              </MenuItem>

              <Box sx={{ flexGrow: 0 }}>
                <Avatar
                  alt={`${fullName}`}
                  onClick={() => {
                    navigate("/settings");
                  }}
                  sx={{ cursor: "pointer" }}
                >
                  {fullName && String(fullName).slice(0, 1)}
                </Avatar>
              </Box>
            </Toolbar>
          </AppBar>

          <Box
            sx={{
              width: "100%",
            }}
          >
            <Outlet />
          </Box>
        </Box>
      </Box>
    </Grid>
  );
}

export default App;
