import React from "react";
import { Button, Modal, Typography, Box } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { componentStyles } from "../componentStyles.js";

const SuccessModal = ({ open, onClose }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="success-modal-title"
      aria-describedby="success-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          borderRadius: "5px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CheckCircleIcon sx={{ color: "green", fontSize: 40 }} />
          <Typography
            variant="h4"
            component="h2"
            sx={{ color: "#142953", fontWeight: 700 }}
          >
            Submit Successful
          </Typography>
        </Box>
        <Typography variant="body1" sx={{ mt: 2 }}>
          We will contact you for document verification. Thanks for signing up
          with LoadCAN.
        </Typography>
        <Button
          variant="contained"
          onClick={onClose}
          sx={componentStyles.submitButtonRegDetails}
        >
          OK
        </Button>
      </Box>
    </Modal>
  );
};

export default SuccessModal;
