import React, { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "./UserContext";

const AdminRoute = ({ component: Component, ...rest }) => {
  const { user } = useContext(UserContext);
  const [isAdmin, setIsAdmin] = useState(false);
  const auth = localStorage.getItem("token");

  const logedInUser = JSON.parse(localStorage.getItem("logedInUser"));
  const adminStatus = logedInUser.user.isAdmin;

  useEffect(() => {
    const checkIsAdmin = async () => {
      if (user?.isAdmin) {
        setIsAdmin(true);
      }
    };

    checkIsAdmin();
  }, [user]);

  if (!auth || !user) {
    return <Navigate to="/login" />;
  }

  if (isAdmin || adminStatus) {
    return <Component {...rest} />;
  }

  return <Navigate to="/" />;
};

export default AdminRoute;
