import React from "react";

const Add = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.7793 8C16.7793 10.1217 15.9364 12.1566 14.4362 13.6569C12.9359 15.1571 10.901 16 8.7793 16C6.65756 16 4.62273 15.1571 3.12244 13.6569C1.62215 12.1566 0.779297 10.1217 0.779297 8C0.779297 5.87827 1.62215 3.84344 3.12244 2.34315C4.62273 0.842855 6.65756 0 8.7793 0C10.901 0 12.9359 0.842855 14.4362 2.34315C15.9364 3.84344 16.7793 5.87827 16.7793 8ZM9.2793 4.5C9.2793 4.36739 9.22662 4.24021 9.13285 4.14645C9.03908 4.05268 8.9119 4 8.7793 4C8.64669 4 8.51951 4.05268 8.42574 4.14645C8.33198 4.24021 8.2793 4.36739 8.2793 4.5V7.5H5.2793C5.14669 7.5 5.01951 7.55268 4.92574 7.64645C4.83198 7.74021 4.7793 7.86739 4.7793 8C4.7793 8.13261 4.83198 8.25979 4.92574 8.35355C5.01951 8.44732 5.14669 8.5 5.2793 8.5H8.2793V11.5C8.2793 11.6326 8.33198 11.7598 8.42574 11.8536C8.51951 11.9473 8.64669 12 8.7793 12C8.9119 12 9.03908 11.9473 9.13285 11.8536C9.22662 11.7598 9.2793 11.6326 9.2793 11.5V8.5H12.2793C12.4119 8.5 12.5391 8.44732 12.6329 8.35355C12.7266 8.25979 12.7793 8.13261 12.7793 8C12.7793 7.86739 12.7266 7.74021 12.6329 7.64645C12.5391 7.55268 12.4119 7.5 12.2793 7.5H9.2793V4.5Z"
        fill="white"
      />
    </svg>
  );
};

export default Add;
