import { useContext } from "react";
import { UserContext } from "./UserContext";
import { Navigate } from "react-router-dom";

import { toast } from "react-toastify";

import { toastOptionsAlt } from "./components/componentStyles";

const PrivateRoute = ({ component: Component, restrictedTo }) => {
  const { user } = useContext(UserContext);
  const auth = localStorage.getItem("token");

  if (!auth || !user) {
    return <Navigate to="/login" />;
  }

  if (user.isDisabled) {
    toast.error(
      "Your account is disabled. Please contact the administrator.",
      toastOptionsAlt
    );
    return <Navigate to="/login" />;
  }

  if (restrictedTo.includes(user.isProfileVerified)) {
    return <Component />;
  }

  return <Navigate to="/" />;
};

export default PrivateRoute;
