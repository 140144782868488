import React from "react";

const Car = ({ color }) => {
  return (
    <svg
      width="11"
      height="9"
      viewBox="0 0 11 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 4.11111H1.22222M11 4.11111H9.77778M9.77778 4.11111L8.55556 1H2.44444L1.22222 4.11111M9.77778 4.11111H1.22222M9.77778 4.11111V8H9.07783V6.83333H1.91393V8H1.22222V4.11111M8 5.5H8.55556M2.44444 5.5H3"
        stroke={color || "white"}
        stroke-width="0.8"
        // fill={color || "white"}
      />
    </svg>
  );
};

export default Car;
