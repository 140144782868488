import { useState, useEffect } from "react";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  IconButton,
  InputAdornment,
  TextField,
  Button,
  CssBaseline,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { componentStyles, toastOptions } from "./componentStyles.js";
import "./componentStyles.css";
import { Formik } from "formik";
import axios from "axios";
import { toast } from "react-toastify";
import * as Yup from "yup";

// Creating schema
const schema = Yup.object().shape({
  password: Yup.string().min(6).required("Please enter your password"),
  confirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

const ResetPasswordPage = () => {
  const theme = createTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(
      (prevShowConfirmPassword) => !prevShowConfirmPassword
    );
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    setToken(token);
  }, [location.search]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Grid container component="main" sx={{ height: "100vh" }}>
          <CssBaseline />
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                mx: 8,
              }}
            >
              <Typography
                variant="h1"
                fontSize={componentStyles.font50Px}
                style={componentStyles.pageHeading}
              >
                Reset Password
              </Typography>
              <Typography
                variant="subtitle1"
                fontSize={componentStyles.font24Px}
                style={componentStyles.pageSubHeading}
              >
                Please Enter Your New Password
              </Typography>
              <Formik
                validationSchema={schema}
                initialValues={{ password: "", confirmPassword: "" }}
                onSubmit={async (values) => {
                  try {
                    const { password } = values;

                    var data = JSON.stringify({
                      password: password,
                      token: token,
                    });

                    var config = {
                      method: "post",
                      url: `${process.env.REACT_APP_BACKEND_URL}/auth/reset-password`,
                      headers: {
                        "Content-Type": "application/json",
                      },
                      data: data,
                    };

                    axios(config)
                      .then(function (response) {
                        toast.success(
                          "Password successfully reset.",
                          componentStyles.toastOptions
                        );
                        setTimeout(() => {
                          navigate("/login");
                        }, 5000);
                      })
                      .catch(function (err) {
                        toast.error(
                          err?.response?.data?.message
                            ? err.response.data.message
                            : "Something went wrong",
                          componentStyles.toastOptions
                        );
                      });
                  } catch (error) {
                    console.error(error);
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <div>
                    <Box sx={{ mt: 5 }}>
                      <form noValidate onSubmit={handleSubmit}>
                        <TextField
                          fullWidth
                          name="password"
                          label="Enter Password"
                          type={showPassword ? "text" : "password"}
                          id="password"
                          autoComplete="off"
                          value={values.password}
                          onChange={handleChange}
                          error={touched.password && Boolean(errors.password)}
                          helperText={touched.password && errors.password}
                          onBlur={handleBlur}
                          sx={componentStyles.customTextField9px}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          margin="normal"
                          fullWidth
                          required
                          name="confirmPassword"
                          label="Re-enter Password"
                          type={showConfirmPassword ? "text" : "password"}
                          id="confirmPassword"
                          autoComplete="off"
                          value={values.confirmPassword}
                          onChange={handleChange}
                          error={
                            touched.confirmPassword &&
                            Boolean(errors.confirmPassword)
                          }
                          helperText={
                            touched.confirmPassword && errors.confirmPassword
                          }
                          onBlur={handleBlur}
                          sx={componentStyles.customTextField9px}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle confirm password visibility"
                                  onClick={handleClickShowConfirmPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showConfirmPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />

                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          sx={componentStyles.submitButton}
                        >
                          Reset Password
                        </Button>
                      </form>
                    </Box>
                  </div>
                )}
              </Formik>
            </Box>
          </Grid>
          <Grid
            sx={{ display: { xs: "none", sm: "none", md: "block" } }}
            item
            md={6}
            className="rightElement"
          />
        </Grid>
      </ThemeProvider>
    </>
  );
};

export default ResetPasswordPage;
