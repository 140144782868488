import { createContext, useState, useEffect } from "react";

export const UserContext = createContext({
  user: {},
  setUser: () => {},
  fullName: "",
  setFullName: () => {},
  contactNo: "",
  setContactNo: () => {},
});

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [fullName, setFullName] = useState("");
  const [contactNo, setContactNo] = useState("");

  useEffect(() => {
    const loggedInUserData = JSON.parse(localStorage.getItem("logedInUser"));
    if (loggedInUserData?.user) {
      setUser({
        isVerified: loggedInUserData?.user?.isVerified,
        isProfileVerified: loggedInUserData?.user?.isProfileVerified,
        isDisabled: loggedInUserData?.user?.isDisabled,
        isAdmin: loggedInUserData?.user?.isAdmin,
      });
      setFullName(loggedInUserData?.user?.fullName);
      setContactNo(loggedInUserData?.user?.contactno);
    }
  }, []);

  return (
    <UserContext.Provider
      value={{ fullName, setFullName, user, setUser, contactNo, setContactNo }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
