import React from "react";

const Logout = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1689 13.2587V14.4639C13.0683 15.9705 11.7603 17.0763 10.2532 16.9748H2.91468C1.40656 17.0763 0.0995359 15.9705 0 14.4639V3.41388C0.100541 2.00877 1.40857 0.904957 2.91468 1.00439H10.2532C11.7623 0.902948 13.0683 2.00675 13.1689 3.51531V4.72056C13.0683 5.1223 12.7667 5.42262 12.3645 5.3242C12.0629 5.3242 11.7603 5.02187 11.7603 4.72056V3.51531C11.6598 2.81124 11.0555 2.20965 10.3537 2.3111H2.91265C2.20786 2.3111 1.50407 2.81124 1.50407 3.51531V14.5633C1.60461 15.2674 2.20786 15.87 2.91265 15.7686H10.2512C10.956 15.7686 11.6598 15.2674 11.6598 14.5633V13.3591C11.7603 12.9564 12.0619 12.6561 12.4641 12.7555C12.7667 12.7565 13.0683 12.9574 13.1689 13.2587Z"
        fill="#142953"
      />
      <path
        d="M15.7839 8.33929L14.8801 7.43236C14.677 7.33293 14.4779 7.33293 14.2748 7.43236C14.1753 7.53581 14.1753 7.83712 14.2748 7.93455L14.8801 8.53815H10.1536C10.0531 8.6416 9.854 8.84047 9.854 9.04034C9.854 9.24121 10.0541 9.4411 10.2552 9.4411H14.9816L14.3793 10.0467C14.1783 10.1482 14.1783 10.4474 14.3793 10.6503C14.4799 10.8492 14.7815 10.8492 14.9816 10.6503L15.8865 9.74442C16.1861 9.34367 16.1861 8.73903 15.7839 8.33929Z"
        fill="#142953"
      />
    </svg>
  );
};

export default Logout;
