import { useState, useContext } from "react";
import axios from "axios";

import {
  IconButton,
  InputAdornment,
  TextField,
  Button,
  Box,
  Typography,
} from "@mui/material";

import { Formik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";

import UserContext from "../../UserContext";
import Edit from "../../assets/icons/Edit";
import { settingStyles, toastOptions } from "./styles";

// Creating schema
const schema = Yup.object().shape({
  contactNo: Yup.string()
    .min(7, "Phone number should be at least 7 digits")
    .max(14, "Phone number should not exceed 14 digits")
    .required(),
});

const ContactUpdate = () => {
  const { contactNo, setContactNo } = useContext(UserContext);
  const logedInUser = JSON.parse(localStorage.getItem("logedInUser"));
  const updateContactNo = logedInUser.user.contactno;
  const [isContactEditing, setIsContactEditing] = useState(false);

  const handleContactEditClick = () => {
    setIsContactEditing(!isContactEditing);
  };

  return (
    <>
      <Formik
        validationSchema={schema}
        initialValues={{
          contactNo: updateContactNo,
        }}
        onSubmit={async (values) => {
          try {
            const { contactNo } = values;
            await schema.validate(values, { abortEarly: false });
            // Compare the input old password with the stored hashed password

            const logedInUser = await JSON.parse(
              localStorage.getItem("logedInUser")
            );
            const token = logedInUser.token;

            var data = JSON.stringify({
              contactno: contactNo,
            });

            var config = {
              method: "post",
              url: `${process.env.REACT_APP_BACKEND_URL}/auth/reset-password-profile`,
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              data: data,
            };

            axios(config)
              .then(function (response) {
                setContactNo(contactNo);
                toast.success(
                  "Successfully reset contact no.",
                  settingStyles.toastOptions
                );
                setIsContactEditing(false);
                // Update and clear the form fields on successful submission
                values.contactNo = contactNo;
              })
              .catch(function (err) {
                toast.error(
                  err?.response?.data?.message
                    ? err.response.data.message
                    : "Something went wrong",
                  settingStyles.toastOptions
                );
              });
          } catch (error) {
            console.error(error);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <div>
            <Box>
              <form noValidate onSubmit={handleSubmit}>
                <Typography variant="body1" sx={settingStyles.formLabels}>
                  Contact No.
                </Typography>
                {isContactEditing ? (
                  <>
                    <TextField
                      fullWidth
                      id="contactNo"
                      defaultValue={contactNo}
                      name="contactNo"
                      type="text"
                      value={values.contactNo}
                      onChange={handleChange}
                      error={touched.contactNo && Boolean(errors.contactNo)}
                      helperText={touched.contactNo && errors.contactNo}
                      onBlur={handleBlur}
                      autoComplete="off"
                      sx={{
                        ...settingStyles.customTextField9px,
                        mr: "5px",
                      }}
                      InputProps={{
                        readOnly: false,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleContactEditClick}>
                              <Edit />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      sx={settingStyles.otherButtons}
                    >
                      Save Contact No.
                    </Button>
                  </>
                ) : (
                  <>
                    <TextField
                      fullWidth
                      id="contactNo"
                      defaultValue={contactNo}
                      name="contactNo"
                      type="text"
                      autoComplete="off"
                      sx={settingStyles.customTextField9px}
                      InputProps={{
                        readOnly: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleContactEditClick}>
                              <Edit color="#5A5A5A" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </>
                )}
              </form>
            </Box>
          </div>
        )}
      </Formik>
    </>
  );
};

export default ContactUpdate;
