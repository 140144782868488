export const settingStyles = {
  outerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  outerBox: {
    marginTop: "5%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "50%",
  },

  buttonsBox: {
    marginBottom: "5%",
    width: "100%",
    alignSelf: "end",
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
  },

  pageTitle: {
    fontWeight: "500",
    alignItems: "flex-start",
    color: "#142953",
    fontFamily: "Inter",
    fontSize: "24px",
    marginBottom: "5%",
  },

  formLabels: {
    color: "#142953",
    fontWeight: "500",
    fontFamily: "Inter",
    fontSize: "16px",
    marginBottom: "2%",
  },

  customTextField9px: {
    marginBottom: "3%",
    height: "60px",
    "& .MuiOutlinedInput-root": {
      "& > fieldset": {
        borderRadius: "5px",
        border: "1.01689px solid #142953",
      },
    },
  },

  submitButton: {
    alignSelf: "end",
    width: "fit-content",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: "400",
    letterSpacing: "0.2rem",
    color: "#FFFFFF",
    background: "#142953",
    border: "1px solid #212121",
    borderRadius: "5px",
    textTransform: "capitalize",
    height: "37.84px",
  },

  otherButtons: {
    alignSelf: "end",
    width: "fit-content",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: "400",
    letterSpacing: "0.2rem",
    color: "#FFFFFF",
    background: "#142953",
    border: "1px solid #212121",
    borderRadius: "5px",
    textTransform: "capitalize",
    height: "37.84px",
    // height: { xs: "40px", sm: "40px", md: "37.84px" },
    marginBottom: "5%",
  },
};

export const toastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
  zIndex: 9999,
};
