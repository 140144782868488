import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  TextField,
  CssBaseline,
  Button,
  IconButton,
  InputAdornment,
  Link,
  Box,
  Grid,
  Typography,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import {
  componentStyles,
  toastOptions,
  toastOptionsAlt,
} from "../componentStyles.js";
import "../componentStyles.css";
import MobileLogo from "../icons/MobileLogo.js";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { toast } from "react-toastify";
import { Formik } from "formik";
import * as Yup from "yup";
import { UserContext } from "../../UserContext.js";

// Creating schema
const schema = Yup.object().shape({
  email: Yup.string()
    .required("Email is a required field")
    .email("Invalid email format"),
  password: Yup.string()
    .required("Password is a required field")
    .min(6, "Password must be at least 6 characters"),
});

export default function Login() {
  const theme = createTheme();
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);
  const { setFullName } = useContext(UserContext);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Grid container component="main" sx={{ height: "100vh" }}>
          <CssBaseline />
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                my: { lg: 3, xl: 20 },
                mx: 4,
              }}
            >
              <Box
                sx={{
                  display: { xs: "flex", sm: "flex", md: "none" },
                  justifyContent: "center",
                  alignItems: "center",
                  my: 3,
                }}
              >
                <MobileLogo />
              </Box>
              <Typography
                variant="h1"
                fontSize={componentStyles.font50Px}
                style={componentStyles.pageHeading}
              >
                Login to your account
              </Typography>
              <Typography
                variant="subtitle1"
                fontSize={componentStyles.font24Px}
                style={componentStyles.pageSubHeading}
              >
                Please Enter Your Details
              </Typography>
              <Formik
                validationSchema={schema}
                initialValues={{ email: "", password: "" }}
                onSubmit={async (values) => {
                  try {
                    const { email, password } = values;
                    const { data } = await axios.post(
                      `${process.env.REACT_APP_BACKEND_URL}/auth/login`,
                      { email, password },
                      { headers: { "Content-Type": "application/json" } }
                    );
                    if (!data?.status) {
                      toast.error(data.message, componentStyles.toastOptions);
                    }
                    if (data?.user) {
                      localStorage.setItem(
                        "token",
                        JSON.stringify(data?.user?.verificationToken)
                      );
                      setUser({
                        isVerified: data?.user?.isVerified,
                        isProfileVerified: data?.user?.isProfileVerified,
                        isDisabled: data?.user?.isDisabled,
                        isAdmin: data?.user?.isAdmin,
                      });
                      setFullName(data?.user?.fullName);
                      localStorage.setItem("logedInUser", JSON.stringify(data));
                      // Clear the form fields on successful submission
                      values.email = "";
                      values.password = "";
                      if (data?.user?.isDisabled) {
                        toast.error(
                          "Your account is disabled. Please contact the administrator.",
                          toastOptionsAlt
                        );
                        navigate("/login");
                      }
                      if (data?.user?.isAdmin) {
                        navigate("/users");
                      }
                      if (
                        data?.user?.isVerified &&
                        !data?.user?.isProfileVerified
                      ) {
                        navigate("/register-details");
                      } else if (
                        data?.user?.isVerified &&
                        data?.user?.isProfileVerified &&
                        !data?.user?.isDisabled &&
                        !data?.user?.isAdmin
                      ) {
                        navigate("/dashboard");
                      }
                    }
                  } catch (err) {
                    console.log("error", err);
                    toast.error(err, toastOptionsAlt);
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <div>
                    <Box sx={{ mt: 5 }}>
                      <form noValidate onSubmit={handleSubmit}>
                        <TextField
                          margin="normal"
                          fullWidth
                          id="email"
                          label="Enter Email"
                          name="email"
                          type="email"
                          autoComplete="off"
                          value={values.email}
                          onChange={handleChange}
                          error={touched.email && Boolean(errors.email)}
                          helperText={touched.email && errors.email}
                          onBlur={handleBlur}
                          sx={componentStyles.customTextField20px}
                        />
                        <TextField
                          fullWidth
                          name="password"
                          label="Enter Password"
                          type={showPassword ? "text" : "password"}
                          id="password"
                          autoComplete="off"
                          value={values.password}
                          onChange={handleChange}
                          error={touched.password && Boolean(errors.password)}
                          helperText={touched.password && errors.password}
                          onBlur={handleBlur}
                          sx={componentStyles.customTextField20px}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <Grid
                          item
                          xs
                          sx={{
                            mt: { xs: -1, sm: 0, md: 0, lg: 0, xl: 0 },
                          }}
                        >
                          <Typography sx={{ textAlign: "right" }}>
                            <Link
                              href="./forgot-password"
                              color="#545454"
                              underline="hover"
                              display="inline"
                            >
                              Forgot password?
                            </Link>
                          </Typography>
                        </Grid>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          sx={componentStyles.submitButton}
                        >
                          Continue
                        </Button>
                      </form>
                    </Box>
                    <Grid container justifyContent="center">
                      <Grid item>
                        <Typography sx={componentStyles.buttonSubText}>
                          Don't Have Account?{"\u00A0"}
                          <Link
                            href="../register"
                            sx={componentStyles.formLinks}
                          >
                            <strong style={{ color: " #212121" }}>
                              Sign Up
                            </strong>
                          </Link>
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                )}
              </Formik>
            </Box>
          </Grid>
          <Grid
            sx={{
              display: { xs: "none", sm: "none", md: "flex" },
              justifyContent: "flex-end",
              alignItems: "center",
            }}
            item
            md={6}
            className="rightElement"
          />
        </Grid>
      </ThemeProvider>
    </>
  );
}
