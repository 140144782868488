import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  useMediaQuery,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { modalStyles, viewPost } from "./styles";
import MapCan from "../MapBox/MapBox";

const ViewEquipment = ({ handleClose, open, data }) => {
  const isSmallScreen = useMediaQuery("(max-width:500px)");
  const [equipmentData, setEquipmentData] = useState(null);
  const [originName, setOriginName] = useState(null);
  const [destinationName, setDestinationName] = useState(null);

  const fetchEquipmentData = async (postId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/equipment/getEquipmentById/${postId}`
      );
      setEquipmentData(response.data.equipments);
      setOriginName(response?.data?.equipments?.origin);
      setDestinationName(response?.data?.equipments?.destination);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (data?.id) {
      fetchEquipmentData(data?.id);
    }
  }, [data]);

  useEffect(() => {
    return () => {
      setOriginName(null);
      setDestinationName(null);
      setEquipmentData(null);
    };
  }, []);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={modalStyles.modalOuter}
      >
        <Box
          sx={isSmallScreen ? modalStyles.smallStyleBox : modalStyles.boxOuter}
        >
          <Box sx={modalStyles.boxTitle}>
            <Typography variant="h5" component="div" sx={modalStyles.textTitle}>
              Post ID: {data?.id}
            </Typography>
            <IconButton onClick={handleClose} sx={{ color: "#fff" }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Grid container>
            <Grid item xs={12} sm={12} md={6}>
              {/* SECTION 1*/}
              <Box sx={viewPost.boxSection1}>
                <Grid container md={12}>
                  <Grid item xs={6} sm={6} md={4}>
                    <Typography sx={viewPost.fieldTitles}>Post Age</Typography>
                    <Typography sx={viewPost.fieldContent}>
                      {data?.postAge}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    <Typography sx={viewPost.fieldTitles}>
                      Contact Name
                    </Typography>
                    <Typography sx={viewPost.fieldContent}>
                      {equipmentData?.createdBy}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    <Typography sx={viewPost.fieldTitles}>Size</Typography>
                    <Typography sx={viewPost.fieldContent}>
                      {equipmentData?.size === "full"
                        ? "Full TL"
                        : equipmentData?.size === "less"
                        ? "Less Than TL"
                        : equipmentData?.size === "both"
                        ? "Both"
                        : ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    <Typography sx={viewPost.fieldTitles}>Company</Typography>
                    <Typography sx={viewPost.fieldContent}>
                      {equipmentData?.company}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    <Typography sx={viewPost.fieldTitles}>Contact</Typography>
                    <Typography sx={viewPost.fieldContent}>
                      {equipmentData?.contactno}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    <Typography sx={viewPost.fieldTitles}>Type</Typography>
                    <Typography sx={viewPost.fieldContent}>
                      Equipment
                    </Typography>
                  </Grid>
                  {/* <Grid item xs={6} sm={6} md={4}>
                    <Typography sx={viewPost.fieldTitles}>Credit</Typography>
                    Confirm where this information is supposed to be coming from before removing hardcoding 
                    <Typography sx={viewPost.fieldContent}>87</Typography>
                  </Grid> */}
                  <Grid item xs={6} sm={6} md={4}>
                    <Typography sx={viewPost.fieldTitles}>Load Date</Typography>
                    <Typography sx={viewPost.fieldContent}>
                      {data?.loadDate}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    <Typography sx={viewPost.fieldTitles}>Weight</Typography>
                    <Typography sx={viewPost.fieldContent}>
                      {equipmentData?.weight} lbs
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    <Typography sx={viewPost.fieldTitles}>Length</Typography>
                    <Typography sx={viewPost.fieldContent}>
                      {equipmentData?.length} ft
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    <Typography sx={viewPost.fieldTitles}>Email</Typography>
                    <Typography
                      sx={{ ...viewPost.fieldContent, wordBreak: "break-word" }}
                    >
                      {equipmentData?.email && (
                        <>
                          <Typography component="span">
                            {equipmentData.email.substring(
                              0,
                              equipmentData.email.indexOf("@")
                            )}
                          </Typography>
                          <Typography component="span">@</Typography>
                          <Typography component="span">
                            {equipmentData.email.substring(
                              equipmentData.email.indexOf("@") + 1
                            )}
                          </Typography>
                        </>
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <Typography sx={viewPost.fieldTitles}>Origin</Typography>
                    <Typography sx={viewPost.fieldContent}>
                      {equipmentData?.origin}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <Typography sx={viewPost.fieldTitles}>
                      Destination
                    </Typography>
                    <Typography sx={viewPost.fieldContent}>
                      {equipmentData?.destination}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Divider variant="middle" />
              {/* SECTION 2 - Equipment Types and Comments */}
              <Box sx={viewPost.boxSection2}>
                <Typography sx={viewPost.fieldTitles}>
                  Equipment Types
                </Typography>
                <Box sx={viewPost.boxSelectedCheckboxes}>
                  {equipmentData?.equipmentTypes
                    ?.split(",")
                    .map((attribute, index) => {
                      const formattedEquipments = attribute
                        .trim()
                        .split(" ")
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ");
                      return (
                        <Typography
                          key={index}
                          sx={viewPost.selectedCheckboxes}
                        >
                          {formattedEquipments || "None"}
                        </Typography>
                      );
                    })}
                </Box>
                <Typography sx={viewPost.fieldTitles}>
                  Posting Attributes
                </Typography>
                <Box sx={viewPost.boxSelectedCheckboxes}>
                  {equipmentData?.postingAttributes ? (
                    equipmentData.postingAttributes
                      .split(",")
                      .map((attribute, index) => {
                        const formattedAttribute = attribute
                          .trim()
                          .split(" ")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ");
                        return (
                          <Typography
                            key={index}
                            sx={viewPost.selectedCheckboxes}
                          >
                            {formattedAttribute}
                          </Typography>
                        );
                      })
                  ) : (
                    <Typography sx={viewPost.selectedCheckboxes}>
                      None
                    </Typography>
                  )}
                </Box>
                <Typography sx={viewPost.fieldTitles}>Comments</Typography>
                <TextField
                  multiline
                  rows={3}
                  fullWidth
                  value={equipmentData?.comments}
                  sx={modalStyles.modalFormFields}
                  inputProps={{
                    readOnly: true,
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Box sx={viewPost.mapBoxBox}>
                {originName && destinationName && (
                  <MapCan
                    key={`${originName}-${destinationName}`}
                    originName={originName}
                    destinationName={destinationName}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default ViewEquipment;
