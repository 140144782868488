import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
} from "@mui/material";

const DeleteConfirmationDialog = ({ open, onClose, onDeleteConfirm }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent
        sx={{
          padding: "5%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <DialogContentText
          sx={{
            textAlign: "center",
            color: "#142953",
            fontWeight: "500",
            fontFamily: "Inter",
            fontSize: { sm: "14px", md: "16px", lg: "20px" },
          }}
        >
          Are you sure you want to delete?
        </DialogContentText>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Button
            onClick={onDeleteConfirm}
            size="small"
            style={{
              backgroundColor: "#FF0000",
              color: "#fff",
              mr: "20%",
            }}
          >
            Delete
          </Button>
          <Button
            onClick={onClose}
            size="small"
            style={{
              backgroundColor: "#142953",
              color: "#fff",
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;
