import React from "react";

const Edit = ({ color }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0291573 12.0055L0 15.3002C0 15.4752 0.0583146 15.6793 0.2041 15.7959C0.349886 15.9125 0.524829 16 0.699772 16L3.99453 15.9708C4.16948 15.9708 4.34442 15.8834 4.49021 15.7667L15.8032 4.45376C16.0656 4.19134 16.0656 3.72483 15.8032 3.46241L12.5376 0.196811C12.2752 -0.0656037 11.8087 -0.0656037 11.5462 0.196811L9.27198 2.47107L0.233258 11.5098C0.0874719 11.6556 0.0291573 11.8305 0.0291573 12.0055ZM14.3162 3.95809L13.0333 5.241L10.759 2.96674L12.0419 1.68383L14.3162 3.95809ZM1.4287 12.297L9.76766 3.95809L12.0419 6.23235L3.70296 14.5713H1.4287V12.297Z"
        fill={color || "#142953"}
      />
    </svg>
  );
};

export default Edit;
