import { Box, Grid } from "@mui/material";
import OwnLoads from "../../components/Posting/OwnLoads";

export default function Load() {
  return (
    <>
      <Grid item xs>
        <Box
          sx={{
            my: { xs: 2.5, sm: 2.5, md: 8 },
            mx: 4,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <OwnLoads />
        </Box>
      </Grid>
    </>
  );
}
